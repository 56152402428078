import React, { useState, useEffect } from 'react';
import Placeholder from './placeholder';
import File from './file';

type Props = {
  files: [],
  loading: boolean,
};

/* eslint-disable no-nested-ternary */
export default ({ files, loading }: Props) => {
  // Local state
  const [show_placeholder, setShowPlaceHolder] = useState(loading === null);

  // Delay to show placeholder
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowPlaceHolder(true), 500);
      return () => clearTimeout(timer);
    }

    if (loading === false) {
      setShowPlaceHolder(false);
    }
  }, [loading]);

  return (
    <>
      <div className="flex items-center text-gray-400 pb-2 border-b border-teal-200 mb-2">
        <span className="flex w-4/5 md:w-1/2">Nom</span>
        <span className="hidden md:flex md:w-1/4 md:block">Type</span>
        <span className="hidden md:flex md:w-1/4 md:block">Ajouté le</span>
      </div>
      {show_placeholder
        ? <Placeholder />
        : (
          files.length
            ? files.map(item => <File key={`${item.id}`} data={item} />)
            : <p className="my-4 italic">Dossier vide ...</p>
        )}
    </>
  );
};
