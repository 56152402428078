// Show
export function showNotification(message, template = 'default') {
  const data = typeof message === 'string' || message instanceof String
    ? { message, delay: 5 }
    : message;

  return { type: 'notification:show', payload: { template, ...data } };
}

// Hide
export function hideNotification() {
  return { type: 'notification:hide' };
}

// Destroy
export function destroyNotification() {
  return { type: 'notification:destroy' };
}
