import React, { useState, useCallback } from 'react';
import { Link, navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { removeEvents } from 'actions/events';
import { showNotification } from 'actions/notification';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';
import TextInput from 'components/text-input';
import RichText from 'components/rich-text';
import UploadFiles from 'components/upload-files';

export default function CreateForm() {
  // Local state
  const [files, setFiles] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Handle submit
  const handleSubmit = useCallback(({
    place_name,
    place_street,
    place_postcode,
    place_city,
    start_hour,
    start_minute,
    end_hour,
    end_minute,
    ...data
  }) => {
    fetchjson('POST events', {
      ...data,
      attachments: files.map(item => item._id),
      start_time: start_hour ? `${start_hour.toString().padStart(2, '0')}h${(start_minute || 0).toString().padStart(2, '0')}` : '',
      end_time: end_hour ? `${end_hour.toString().padStart(2, '0')}h${(end_minute || 0).toString().padStart(2, '0')}` : '',
      place: {
        name: place_name,
        street: place_street,
        postcode: place_postcode,
        city: place_city,
      },
    })
      .then(payload => {
        dispatch(showNotification('Évènement ajouté avec succès !', 'success'));
        dispatch(removeEvents());
        navigate(`/events/view/${payload._id}`);
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [files, dispatch, fetchjson]);

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <Link to="/events" className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </Link>
            Créer un nouvel évènement
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <Form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Description</div>
            <div className="w-3/5">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Titre</div>
                <TextInput name="title" required />
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Contenu</div>
                <RichText name="content" />
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Durée</div>
            <div>
              <div className="mb-4 flex items-center">
                <div className="mr-4 w-64">
                  <div className="text-sm text-gray-700 mb-1">Date de début</div>
                  <TextInput name="start_at" type="date" required />
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Heure de début</div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <TextInput name="start_hour" type="number" />
                    </div>
                    <span className="mx-1">:</span>
                    <div className="w-20">
                      <TextInput name="start_minute" type="number" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div className="mr-4 w-64">
                  <div className="text-sm text-gray-700 mb-1">Date de fin</div>
                  <TextInput name="end_at" type="date" required />
                </div>
                <div className="w-64">
                  <div className="text-sm text-gray-700 mb-1">Heure de fin</div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <TextInput name="end_hour" type="number" />
                    </div>
                    <span className="mx-1">:</span>
                    <div className="w-20">
                      <TextInput name="end_minute" type="number" />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Endroit où se déroule l'évènement</div>
            <div className="flex-grow">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Nom du lieu</div>
                <div className="w-2/5">
                  <TextInput name="place_name" />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Adresse</div>
                <div className="w-2/5">
                  <TextInput name="place_street" />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Code postal</div>
                  <div className="w-32">
                    <TextInput name="place_postcode" />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="text-sm text-gray-700 mb-1">Ville</div>
                  <TextInput name="place_city" />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Procédure d'inscription</div>
            <div className="flex-grow">
              <div className="mb-4 w-96">
                <div className="text-sm text-gray-700 mb-1">Lien Google Form</div>
                <TextInput name="link" />
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">
              Pièces jointes
              <br />
              (plan d'accès, ...)
            </div>
            <UploadFiles files={files} onChange={setFiles} />
          </div>
          <div className="my-12 flex items-center ml-60">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="plus" type="submit">Ajouter l'évènement</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
