import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFetch from './fetch';

// Credential hook
export default email => {
  // Local state
  const [credential, setCredential] = useState({});

  // Redux
  const fetchjson = useFetch();
  const role = useSelector(state => state.role);

  // Fetch credentials
  useEffect(() => {
    if (role === 'admin' && email) {
      fetchjson(`credentials/${email}`).then(setCredential).catch(() => null);
    }
  }, [role, email, fetchjson]);

  // Return credential ... or empty data
  return credential;
};
