import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import ErrorPage from 'pages/error';
import IfAdmin from 'components/if-admin';
import Button from 'components/button';
import Icon from 'components/icon';
import Delay from 'components/delay';
import useUser from 'hooks/user';
import useCredential from 'hooks/credential';

function genderToString(value) {
  if (['madame', 'mme'].includes((value || '').trim().toLowerCase())) {
    return 'Mme';
  }

  if (['monsieur', 'mr'].includes((value || '').trim().toLowerCase())) {
    return 'Mr';
  }

  return '';
}

type Props = {
  id: string,
};

export default function MemberDetails({ id }: Props) {
  // Redux
  const user = useUser(id);
  const { password = '' } = useCredential(user?.email);

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!user || typeof user === 'string') {
    return (
      <>
        <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
          <div className="container mx-auto">
            <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
              <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
                <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
              </button>
              Fiche détaillée
            </h2>
          </div>
        </div>
        <div className="container my-8 px-4 min-h-screen md:my-12 md:mx-auto 2xl:px-0">
          {user
            ? (
              <ErrorPage message={user} />
            )
            : (
              <Delay>
                <Icon name="loader" className="animate-spin inline-block w-4 h-4" />
              </Delay>
            )}
        </div>
      </>
    );
  }

  const work = [];

  if (user.job) {
    work.push(user.job);
  }

  if (user.organization) {
    work.push(user.organization);
  }

  return (
    <>
      <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
            </button>
            Fiche détaillée
          </h2>
        </div>
      </div>
      <div className="fade-in container my-8 px-4 min-h-screen md:my-12 md:mx-auto 2xl:px-0">
        <div className="md:grid md:gap-12 md:grid-cols-12">
          <div className="col-span-5 mb-8 md:mb-0">
            <div className="sticky top-12">
              <div className="flex items-center">
                <div>
                  <span className="flex items-center justify-center text-teal-600 text-xl h-16 w-16 rounded-full bg-teal-100 mr-4 md:mr-6 md:text-2xl md:h-20 md:w-20">
                    {`${(user.lastname || ' ')[0].toUpperCase()}${(user.firstname || ' ')[0].toUpperCase()}`}
                  </span>
                </div>
                <div className="md:truncate">
                  <h2 className="text-2xl font-semibold whitespace-normal">
                    {`${genderToString(user.gender)} ${user.lastname} ${user.firstname}`}
                  </h2>
                  <p className="hidden text-lg md:block md:truncate">
                    {work.join(', ')}
                  </p>
                </div>
              </div>
              <IfAdmin>
                <div className="hidden md:mt-12 md:flex md:items-center md:justify-center">
                  <Button icon="edit" to={`/members/edit/${user._id}`}>Modifier</Button>
                  <Button icon="send" to={`/members/send-password/${user._id}`} className="ml-4">Envoyer les accès</Button>
                  <Button icon="trash-2" to={`/members/delete/${user._id}`} className="ml-4">Supprimer</Button>
                </div>
              </IfAdmin>
            </div>
          </div>
          <div className="col-span-7">
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Fonction :</span>
              <span>
                {user.job || '-'}
                <br />
                {user.job2 || ''}
              </span>
            </div>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Organisation :</span>
              <span>{user.organization || '-'}</span>
            </div>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Adresse :</span>
              <div>
                {user.address
                  ? (
                    <>
                      {user.address.street}
                      <br />
                      {`${user.address.postcode || ''} ${user.address.city || ''}`}
                      <a
                        href={`https://maps.google.com/?q=${user.organization || ''} ${user.address.street || ''} ${user.address.postcode || ''} ${user.address.city || ''}`}
                        target="_blank"
                        rel="noreferrer"
                        className="mt-4 text-blue-600 flex items-center"
                      >
                        <Icon name="map-pin" className="mr-1" />
                        Localiser sur la carte
                      </a>
                    </>
                  )
                  : '-'}
              </div>
            </div>
            <hr className="h-px border-none bg-teal-100 mx-0 my-8" />
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Email :</span>
              {user.email
                ? <a href={`mailto:${user.email}`} target="_blank" rel="noreferrer" className="text-blue-600">{user.email}</a>
                : '-'}
            </div>
            <IfAdmin>
              <div className="flex flex-col mb-4 md:flex-row">
                <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Mot de passe :</span>
                {password}
              </div>
            </IfAdmin>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Téléphone :</span>
              {user.phone
                ? <a href={`tel://${user.phone}`} target="_blank" rel="noreferrer" className="text-blue-600">{user.phone}</a>
                : '-'}
            </div>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Tél. pro :</span>
              {user.phone_pro
                ? <a href={`tel://${user.phone_pro}`} target="_blank" rel="noreferrer" className="text-blue-600">{user.phone_pro}</a>
                : '-'}
            </div>
            {(user.assistant_name || user.assistant_phone || user.assistant_email) && (
              <>
                <hr className="h-px border-none bg-teal-100 mx-0 my-8" />
                <div className="flex flex-col mb-4 md:flex-row">
                  <span className="text-gray-500 md:w-40 md:text-right md:mr-8">{`${user.assistant_label || 'Assistant(e)'} :`}</span>
                  <div>
                    {user.assistant_name && <p>{user.assistant_name}</p>}
                    {user.assistant_phone && (
                      <p className="flex items-center space-x-2">
                        <Icon name="phone" className="text-sm text-gray-500" />
                        <a href={`tel:://${user.assistant_phone}`} target="_blank" rel="noreferrer" className="text-blue-600">{user.assistant_phone}</a>
                      </p>
                    )}
                    {user.assistant_email && (
                      <p className="flex items-center space-x-2">
                        <Icon name="at-sign" className="text-sm text-gray-500" />
                        <a href={`mailto:${user.assistant_email}`} target="_blank" rel="noreferrer" className="text-blue-600">{user.assistant_email}</a>
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            <hr className="h-px border-none bg-teal-100 mx-0 my-8" />
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Twitter :</span>
              {user.twitter
                ? <a href={user.twitter} target="_blank" rel="noreferrer" className="text-blue-600">{user.twitter}</a>
                : '-'}
            </div>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">LinkedIn :</span>
              {user.linkedin
                ? <a href={user.linkedin} target="_blank" rel="noreferrer" className="text-blue-600">{user.linkedin}</a>
                : '-'}
            </div>
            <div className="flex flex-col mb-4 md:flex-row">
              <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Site internet :</span>
              {user.web
                ? <a href={user.web} target="_blank" rel="noreferrer" className="text-blue-600">{user.web}</a>
                : '-'}
            </div>
            <IfAdmin>
              {user.expires_at
                ? (
                  <>
                    <hr className="h-px border-none bg-teal-100 mx-0 my-8" />
                    <div className="flex flex-col mb-4 md:flex-row">
                      <span className="text-gray-500 md:w-40 md:text-right md:mr-8">Expire le :</span>
                      <span className="flex items-center">
                        {new Date(user.expires_at).toLocaleDateString()}
                        {new Date(user.expires_at) < new Date()
                          ? <span className="ml-4 bg-orange-700 rounded-full px-3 py-1 text-xs text-white">Impayée</span>
                          : null}
                      </span>
                    </div>
                  </>
                )
                : null}
            </IfAdmin>
          </div>
        </div>
      </div>
    </>
  );
}
