import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import useUser from 'hooks/user';
import useCredential from 'hooks/credential';
import { showNotification } from 'actions/notification';
import ErrorPage from 'pages/error';
import Button from 'components/button';
import Delay from 'components/delay';
import Form from 'components/form';
import Icon from 'components/icon';
import Select from 'components/select';
import TextInput from 'components/text-input';

function getGender(value) {
  const gender = (value || '').trim().toLowerCase();

  switch (gender) {
    case 'madame':
    case 'mme':
      return 'Madame';

    case 'monsieur':
    case 'mr':
      return 'Monsieur';

    default:
      return gender;
  }
}

type Props = {
  id: string,
};

export default function EditForm({ id }: Props) {
  // Redux
  const user = useUser(id);
  const { password = '' } = useCredential(user?.email);
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Set expires_at
  if (user?.expires_at) {
    const date = new Date(user.expires_at);
    user.expires_at = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  // Handle submit
  const handleSubmit = useCallback(({
    address_street,
    address_postcode,
    address_city,
    ...data
  }) => {
    fetchjson(`PUT users/${id}`, {
      ...data,
      address: {
        street: address_street,
        postcode: address_postcode,
        city: address_city,
      },
      expires_at: data.expires_at === 'available' ? `${new Date().getFullYear() + 1}-03-01` : '2021-04-15',
    })
      .then(() => {
        dispatch(showNotification('Membre modifié avec succès !', 'success'));
        navigate(-1);
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [id, dispatch, fetchjson]);

  // Error ?
  if (!user || typeof user === 'string') {
    return (
      <>
        <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
          <div className="container mx-auto">
            <h2 className="text-xl text-white font-semibold flex items-center">
              <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
                <Icon name="arrow-left" className="text-3xl text-white" />
              </button>
              Modifier le membre
            </h2>
          </div>
        </div>
        <div className="container mx-auto my-12">
          {user
            ? <ErrorPage message={user} />
            : (
              <Delay>
                <Icon name="loader" className="animate-spin inline-block w-4 h-4" />
              </Delay>
            )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </button>
            Modifier le membre
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <Form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. générales</div>
            <div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Titre</div>
                <div className="w-48">
                  <Select
                    name="gender"
                    defaultValue={getGender(user.gender)}
                    values={{ Madame: 'Madame', Monsieur: 'Monsieur' }}
                  />
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Nom</div>
                  <div className="w-60">
                    <TextInput name="lastname" defaultValue={user.lastname} required />
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Prénom</div>
                  <div className="w-60">
                    <TextInput name="firstname" defaultValue={user.firstname} required />
                  </div>
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. de connexion</div>
            <div>
              <div className="mb-4 flex items-center">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Email</div>
                  <div className="w-60">
                    <TextInput name="email" type="email" defaultValue={user.email} required />
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Mot de passe</div>
                  <div className="w-60">
                    <TextInput name="password" defaultValue={password} required />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">État de la cotisation</div>
                <div className="w-48">
                  <Select
                    name="expires_at"
                    defaultValue={new Date(user.expires_at) > new Date() ? 'available' : 'expired'}
                    values={{ expired: 'Non payée', available: 'Payée' }}
                  />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. professionnelles</div>
            <div className="flex-grow">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Organisme</div>
                <div className="w-2/5">
                  <TextInput name="organization" defaultValue={user.organization} />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-4">
                  <div className="text-sm text-gray-700 mb-1">Fonction</div>
                  <TextInput name="job" defaultValue={user.job} />
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Autre fonction</div>
                  <TextInput name="job2" defaultValue={user.job2} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Adresse</div>
                <div className="w-2/5">
                  <TextInput name="address_street" defaultValue={user.address?.street} />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Code postal</div>
                  <div className="w-32">
                    <TextInput name="address_postcode" defaultValue={user.address?.postcode} />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="text-sm text-gray-700 mb-1">Ville</div>
                  <TextInput name="address_city" defaultValue={user.address?.city} />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. de contact</div>
            <div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Téléphone</div>
                  <div className="w-60">
                    <TextInput name="phone" defaultValue={user.phone} />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">Téléphone pro</div>
                  <div className="w-60">
                    <TextInput name="phone_pro" defaultValue={user.phone_pro} />
                  </div>
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Twitter</div>
                  <div className="w-60">
                    <TextInput name="twitter" defaultValue={user.twitter} />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">LinkedIn</div>
                  <div className="w-60">
                    <TextInput name="linkedin" defaultValue={user.linkedin} />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Site internet</div>
                <div className="w-80">
                  <TextInput name="web" defaultValue={user.web} />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Assistant(e)</div>
            <div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Fonction</div>
                  <div className="w-60">
                    <TextInput name="assistant_label" type="text" defaultValue={user.assistant_label} placeholder="Assistant(e)" />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">Nom & Prénom</div>
                  <div className="w-60">
                    <TextInput name="assistant_name" defaultValue={user.assistant_name} />
                  </div>
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Téléphone</div>
                  <div className="w-60">
                    <TextInput name="assistant_phone" defaultValue={user.assistant_phone} />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">Email</div>
                  <div className="w-60">
                    <TextInput name="assistant_email" type="email" defaultValue={user.assistant_email} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-12 flex items-center ml-60">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="edit" type="submit">Modifier le membre</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
