import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'hooks/fetch';
import useDebounce from 'hooks/debounce';
import { showNotification } from 'actions/notification';
import Breadcrumbs from './components/breadcrumbs';
import SearchInput from './components/search-input';
import Table from './components/table';

type Props = {
  id?: string,
};

export default function FileManager({ id = '' }: Props) {
  // Local state
  const [loading, setLoading] = useState(null);
  const [searching, setSearching] = useState(null);
  const [query, setQuery] = useState('');
  const [tree, setTree] = useState({});
  const [files, setFiles] = useState([]);
  const debounced_query = useDebounce(query, 150);

  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();
  const role = useSelector(state => state.role);

  // Fetch tree
  useEffect(() => {
    fetchjson('filemanager/tree')
      .then(setTree)
      .catch(({ message }) => dispatch(showNotification(message, 'error')));
  }, [fetchjson, dispatch]);

  // Fetch files
  useEffect(() => {
    // Reset query
    setQuery('');

    // Loading
    if (loading === false) {
      setLoading(true);
    }

    // Fetch data
    fetchjson('filemanager', { folder: id })
      .then(setFiles)
      .catch(({ message }) => dispatch(showNotification(message, 'error')))
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchjson, dispatch]);

  // Search files
  useEffect(() => {
    if (searching && debounced_query.length) {
      // Loading
      setLoading(true);

      // Fetch data
      fetchjson('filemanager', { q: debounced_query })
        .then(setFiles)
        .catch(({ message }) => dispatch(showNotification(message, 'error')))
        .finally(() => setLoading(false));
    }
  }, [searching, debounced_query, fetchjson, dispatch]);

  // Resync folder if query empty
  useEffect(() => {
    if (searching === false && !query.length) {
      // Loading
      setLoading(true);

      // Fetch data
      fetchjson('filemanager', { folder: id })
        .then(setFiles)
        .catch(({ message }) => dispatch(showNotification(message, 'error')))
        .finally(() => setLoading(false));
    }
  }, [searching, query, id, fetchjson, dispatch]);

  // Get quota
  useEffect(() => {
    async function fetchQuota() {
      if (role === 'admin') {
        // No cache valid ?
        if (!sessionStorage.storageQuota) {
          // Load data & compute value
          const data = await fetchjson('filemanager/quota');
          sessionStorage.storageQuota = `${Math.round(Number(data.usage) / 1073741824)} / ${Math.round(Number(data.limit) / 1073741824)} Go`;
        }
      }
    }

    fetchQuota();
  }, [role, fetchjson]);

  return (
    <>
      <div className="py-6 md:py-20" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-white font-semibold text-center md:text-xl">
            Documents administratifs & Historiques
            <small className="block text-sm font-normal md:text-base">
              Accès aux ressources de l'association
              {sessionStorage.storageQuota && ` - Quota : ${sessionStorage.storageQuota}`}
            </small>
          </h2>
        </div>
      </div>
      <div className="container my-6 px-4 min-h-screen md:my-8 md:mx-auto 2xl:px-0">
        <div className="flex flex-col justify-center justify-between mb-4 md:flex-row md:items-center md:justify-between md:mb-8">
          <Breadcrumbs tree={tree} current={searching || query ? null : id} />
          <SearchInput
            query={query}
            onChange={setQuery}
            onFocus={() => setSearching(true)}
            onBlur={() => setSearching(false)}
          />
        </div>
        <Table files={files} loading={loading} />
      </div>
    </>
  );
}
