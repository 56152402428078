import React from 'react';
import classNames from 'classnames';

// Letters A to Z
const AZ = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

type Props = {
  letter: string,
  letters: [],
  onClick: () => null,
};

export default ({ letter, letters, onClick }: Props) => (
  <div className="hidden md:block">
    <p className="text-sm text-gray-600 mb-2">Index alphabétique</p>
    <div className="flex justify-between">
      {AZ.map(item => (
        letters.includes(item)
          ? (
            <button
              key={`alphabet-${item}`}
              type="button"
              onClick={() => onClick(letter === item ? null : item)}
              className={classNames(
                'p-1 inline-block text-lg text-red-900 font-semibold leading-none rounded border border-white focus:outline-none',
                { 'border-red-900': letter === item },
              )}
            >
              {item}
            </button>
          )
          : <span className="cursor-default p-1 inline-block text-lg text-gray-300 font-semibold leading-none border border-white">{item}</span>
      ))}
    </div>
  </div>
);
