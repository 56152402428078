import React from 'react';
import Icon from 'components/icon';
import MONTHS from '../../months';

type Props = {
  start_date: Date,
  start_time: string,
  end_date: Date,
  end_time: string,
};

export default function Duration({
  start_date,
  start_time,
  end_date,
  end_time,
}: Props) {
  // Cast dates
  const start = new Date(start_date);
  const end = new Date(end_date);

  // Same day ?
  if (start.getFullYear() === end.getFullYear()
    && start.getMonth() === end.getMonth()
    && start.getDate() === end.getDate()
  ) {
    return (
      <div className="flex font-light items-center md:text-xl">
        <Icon name="calendar" className="text-blue-600 mr-2" />
        {`${start.getDate().toString().padStart(2, '0')} ${MONTHS[start.getMonth()]}`}
        {start_time
          ? (
            <>
              <Icon name="clock" className="text-blue-600 ml-8 mr-2" />
              {`${start_time}${end_time ? ' - '.concat(end_time) : ''}`}
            </>
          )
          : null}
      </div>
    );
  }

  return (
    <div className="flex font-light items-center md:text-xl">
      <Icon name="calendar" className="text-blue-600 mr-2" />
      {`Du ${start.getDate().toString().padStart(2, '0')} ${MONTHS[start.getMonth()]} ${start_time} au ${end.getDate().toString().padStart(2, '0')} ${MONTHS[end.getMonth()]} ${end_time}`}
    </div>
  );
}
