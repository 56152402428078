import React, { useState, useCallback, useEffect } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { removeEvents } from 'actions/events';
import { showNotification } from 'actions/notification';
import ErrorPage from 'pages/error';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';
import TextInput from 'components/text-input';
import RichText from 'components/rich-text';
import UploadFiles from 'components/upload-files';

type Props = {
  id: string,
};

export default function EditForm({ id }: Props) {
  // Local state
  const [event, setEvent] = useState({});

  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Fetch event
  useEffect(() => {
    fetchjson(`events/${id}`)
      .then(setEvent)
      .catch(({ message }) => setEvent(message));
  }, [id, fetchjson]);

  // Handle submit
  const handleSubmit = useCallback(({
    place_name,
    place_street,
    place_postcode,
    place_city,
    start_hour,
    start_minute,
    end_hour,
    end_minute,
    ...data
  }) => {
    fetchjson(`PUT events/${id}`, {
      ...data,
      attachments: event.attachments.map(item => item._id),
      start_time: start_hour ? `${start_hour.toString().padStart(2, '0')}h${(start_minute || 0).toString().padStart(2, '0')}` : '',
      end_time: end_hour ? `${end_hour.toString().padStart(2, '0')}h${(end_minute || 0).toString().padStart(2, '0')}` : '',
      place: {
        name: place_name,
        street: place_street,
        postcode: place_postcode,
        city: place_city,
      },
    })
      .then(() => {
        dispatch(showNotification('Évènement modifié avec succès !', 'success'));
        dispatch(removeEvents());
        navigate(`/events/view/${id}`);
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [event, id, dispatch, fetchjson]);

  // Error ?
  if (typeof event === 'string') {
    return (
      <>
        <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
          <div className="container mx-auto">
            <h2 className="text-xl text-white font-semibold flex items-center">
              <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
                <Icon name="arrow-left" className="text-3xl text-white" />
              </button>
              Modifier l'évènement
            </h2>
          </div>
        </div>
        <div className="container mx-auto my-12">
          <ErrorPage message={event} />
        </div>
      </>
    );
  }

  // Set start_at
  if (event.start_at) {
    const date = new Date(event.start_at);
    event.start_at = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  // Set end_at
  if (event.end_at) {
    const date = new Date(event.end_at);
    event.end_at = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  // Set start_hour and start_minute
  const [start_hour, start_minute] = (event?.start_time || '').split('h');
  const [end_hour, end_minute] = (event?.end_time || '').split('h');

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </button>
            Modifier l'évènement
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <Form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Description</div>
            <div className="w-3/5">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Titre</div>
                <TextInput name="title" defaultValue={event.title} required />
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Contenu</div>
                <RichText name="content" defaultValue={event.content} />
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Durée</div>
            <div>
              <div className="mb-4 flex items-center">
                <div className="mr-4 w-64">
                  <div className="text-sm text-gray-700 mb-1">Date de début</div>
                  <TextInput name="start_at" type="date" defaultValue={event.start_at} required />
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Heure de début</div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <TextInput name="start_hour" defaultValue={start_hour} type="number" />
                    </div>
                    <span className="mx-1">:</span>
                    <div className="w-20">
                      <TextInput name="start_minute" defaultValue={start_minute} type="number" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div className="mr-4 w-64">
                  <div className="text-sm text-gray-700 mb-1">Date de fin</div>
                  <TextInput name="end_at" type="date" defaultValue={event.end_at} required />
                </div>
                <div className="w-64">
                  <div className="text-sm text-gray-700 mb-1">Heure de fin</div>
                  <div className="flex items-center">
                    <div className="w-20">
                      <TextInput name="end_hour" defaultValue={end_hour} type="number" />
                    </div>
                    <span className="mx-1">:</span>
                    <div className="w-20">
                      <TextInput name="end_minute" defaultValue={end_minute} type="number" />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Endroit où se déroule l'évènement</div>
            <div className="flex-grow">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Nom du lieu</div>
                <div className="w-2/5">
                  <TextInput name="place_name" defaultValue={event?.place?.name} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Adresse</div>
                <div className="w-2/5">
                  <TextInput name="place_street" defaultValue={event?.place?.street} />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Code postal</div>
                  <div className="w-32">
                    <TextInput name="place_postcode" defaultValue={event?.place?.postcode} />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="text-sm text-gray-700 mb-1">Ville</div>
                  <TextInput name="place_city" defaultValue={event?.place?.city} />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Procédure d'inscription</div>
            <div className="flex-grow">
              <div className="mb-4 w-96">
                <div className="text-sm text-gray-700 mb-1">Lien Google Form</div>
                <TextInput name="link" defaultValue={event?.link} />
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">
              Pièces jointes
              <br />
              (plan d'accès, ...)
            </div>
            <UploadFiles
              files={event.attachments || []}
              onChange={items => setEvent({ ...event, attachments: items })}
            />
          </div>
          <div className="my-12 flex items-center ml-60">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="edit" type="submit">Modifier l'évènement</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
