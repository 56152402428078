import { combineReducers } from 'redux';

// Reducers
import credentials from './credentials';
import role from './role';
import notification from './notification';
import display from './display';
import randomly from './random-users';
import events from './events';

export default combineReducers({
  credentials,
  role,
  notification,
  display,
  randomly,
  events,
});
