import React from 'react';
import { Router } from '@reach/router';

// Pages
import Layout from 'components/layout';
import Notification from 'components/notification';
import MembersHome from './members/home';
import MembersDetails from './members/details';
import MembersCreate from './members/create';
import MembersEdit from './members/edit';
import MembersSendPassword from './members/send-password';
import MembersDelete from './members/delete';
import MembersUpload from './members/upload';
import EventsHome from './events/home';
import EventsDetails from './events/details';
import EventsCreate from './events/create';
import EventsEdit from './events/edit';
import EventsDelete from './events/delete';
import FileManager from './filemanager';
import Me from './me';
import MeRequest from './me/request';
import MePassword from './me/password';
import SignIn from './signin';
import NotFound from './error';

export default function App() {
  return (
    <>
      <Router primary={false}>
        <Layout path="/">
          <MembersHome path="/" />
          <MembersCreate path="/members/create" />
          <MembersDetails path="/members/view/:id" />
          <MembersEdit path="/members/edit/:id" />
          <MembersDelete path="/members/delete/:id" />
          <MembersSendPassword path="/members/send-password/:id" />
          <MembersUpload path="/members/upload" />
          <EventsHome path="/events" />
          <EventsCreate path="/events/create" />
          <EventsDetails path="/events/view/:id" />
          <EventsEdit path="/events/edit/:id" />
          <EventsDelete path="/events/delete/:id" />
          <FileManager path="/filemanager" />
          <FileManager path="/filemanager/:id" />
          <Me path="/me" />
          <MeRequest path="/me/request" />
          <MePassword path="/me/password" />
          <NotFound default />
        </Layout>
        <SignIn path="/signin" />
      </Router>
      <Notification />
    </>
  );
}
