import React, { useState, useEffect } from 'react';
import Placeholer from './placeholder';
import Table from './table';
import Cards from './cards';

type Props = {
  data: [] | null,
  display: string,
  loading: boolean,
};

export default ({ data, display, loading }: Props) => {
  // Local state
  const [placeholer, setPlaceholder] = useState();

  // Loading ?
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setPlaceholder(true), 300);
      return () => clearTimeout(timer);
    }

    setPlaceholder(false);
  }, [loading]);

  // Place holder ?
  if (placeholer) {
    return <Placeholer display={display} />;
  }

  // Display nothing until placeholder is set to true
  if (loading) {
    return null;
  }

  if (!data) {
    return (
      <div className="flex items-center justify-between mb-8">
        Chargement en cours ...
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="text-center italic text-gray-500">
        Aucun résultat
      </div>
    );
  }

  return display === 'table'
    ? <Table data={data} />
    : <Cards data={data} />;
};
