import React, { useCallback } from 'react';
import { Link, navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { showNotification } from 'actions/notification';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';
import Select from 'components/select';
import TextInput from 'components/text-input';

export default function CreateForm() {
  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Handle submit
  const handleSubmit = useCallback(({
    address_street,
    address_postcode,
    address_city,
    ...data
  }) => {
    fetchjson('POST users', {
      ...data,
      address: {
        street: address_street,
        postcode: address_postcode,
        city: address_city,
      },
      expires_at: data.expires_at === 'available' ? `${new Date().getFullYear() + 1}-03-01` : '2021-04-15',
    })
      .then(payload => {
        dispatch(showNotification('Membre ajouté avec succès !', 'success'));
        navigate(`/members/view/${payload._id}`);
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, fetchjson]);

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <Link to="/" className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </Link>
            Créer un nouveau membre
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <Form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. générales</div>
            <div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Titre</div>
                <div className="w-48">
                  <Select
                    name="gender"
                    values={{ Madame: 'Madame', Monsieur: 'Monsieur' }}
                  />
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Nom</div>
                  <div className="w-60">
                    <TextInput name="lastname" required />
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Prénom</div>
                  <div className="w-60">
                    <TextInput name="firstname" required />
                  </div>
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. de connexion</div>
            <div>
              <div className="mb-4 flex items-center">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Email</div>
                  <div className="w-60">
                    <TextInput name="email" type="email" required />
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Mot de passe</div>
                  <div className="w-60">
                    <TextInput name="password" defaultValue={(Math.random() + 0.1).toString(36).split('.').pop()} required />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">État de la cotisation</div>
                <div className="w-48">
                  <Select
                    name="expires_at"
                    values={{ expired: 'Non payée', available: 'Payée' }}
                  />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. professionnelles</div>
            <div className="flex-grow">
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Organisme</div>
                <div className="w-2/5">
                  <TextInput name="organization" />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-4">
                  <div className="text-sm text-gray-700 mb-1">Fonction</div>
                  <TextInput name="job" />
                </div>
                <div>
                  <div className="text-sm text-gray-700 mb-1">Autre fonction</div>
                  <TextInput name="job2" />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Adresse</div>
                <div className="w-2/5">
                  <TextInput name="address_street" />
                </div>
              </div>
              <div className="mb-4 flex items-center w-2/5">
                <div className="mr-8">
                  <div className="text-sm text-gray-700 mb-1">Code postal</div>
                  <div className="w-32">
                    <TextInput name="address_postcode" />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="text-sm text-gray-700 mb-1">Ville</div>
                  <TextInput name="address_city" />
                </div>
              </div>
              <hr className="w-32 h-px border-none bg-teal-100 mx-0 my-8" />
            </div>
          </div>
          <div className="flex">
            <div className="w-60 text-gray-500 italic">Infos. de contact</div>
            <div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Libellé assistant(e)</div>
                  <div className="w-60">
                    <TextInput name="assistant_label" type="text" placeholder="Assistant(e)" />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">Email assistant(e)</div>
                  <div className="w-60">
                    <TextInput name="assistant_email" type="email" />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Téléphone</div>
                <div className="w-60">
                  <TextInput name="phone" />
                </div>
              </div>
              <div className="mb-4 flex items-center">
                <div>
                  <div className="text-sm text-gray-700 mb-1">Twitter</div>
                  <div className="w-60">
                    <TextInput name="twitter" />
                  </div>
                </div>
                <div className="ml-8">
                  <div className="text-sm text-gray-700 mb-1">LinkedIn</div>
                  <div className="w-60">
                    <TextInput name="linkedin" />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-sm text-gray-700 mb-1">Site internet</div>
                <div className="w-80">
                  <TextInput name="web" />
                </div>
              </div>
            </div>
          </div>
          <div className="my-12 flex items-center ml-60">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="user-plus" type="submit">Ajouter le membre</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
