import React, { useCallback, useRef } from 'react';

type Props = {
  onSubmit?: () => null,
};

export default ({ onSubmit = () => null, ...rest }: Props) => {
  // Ref
  const ref = useRef({});

  const handleSubmit = useCallback(event => {
    event.preventDefault();
    onSubmit(Object.fromEntries(new FormData(ref.current)));
  }, [onSubmit]);

  return <form ref={ref} onSubmit={handleSubmit} {...rest} />;
};
