import React from 'react';
import { navigate, Link } from '@reach/router';
import IfAdmin from 'components/if-admin';
import Dropdown from 'components/dropdown';
import TextInput from 'components/text-input';
import Icon from 'components/icon';

type Props = {
  query: string | null,
};

export default ({ query }: Props) => (
  <div className="flex items-center relative">
    <TextInput
      value={query}
      onChange={event => navigate(`?q=${encodeURIComponent(event.target.value)}`, { replace: true })}
      placeholder="Rechercher par nom, prénom, organisme, email, adresse ..."
      suffix={query
        ? (
          <button type="button" onClick={() => navigate('/')}>
            <Icon name="x" className="text-red-900" />
          </button>
        )
        : 'search'}
      containerClassName="w-full"
    />
    <IfAdmin>
      <Dropdown label="Administrer" className="ml-4 hidden md:block">
        <Link to="/members/create" className="flex items-center px-4 py-2 hover:bg-red-900 hover:text-white">
          <Icon name="user-plus" className="mr-2" />
          Ajouter un membre
        </Link>
        <Link to="/members/upload" className="flex items-center px-4 py-2 hover:bg-red-900 hover:text-white">
          <Icon name="upload-cloud" className="mr-2" />
          Importer au format XLS
        </Link>
      </Dropdown>
    </IfAdmin>
  </div>
);
