import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { showNotification } from 'actions/notification';

export default () => {
  // Redux
  const dispatch = useDispatch();
  const credentials = useSelector(state => state.credentials);

  return useCallback(file => {
    fetch(`${process.env.REACT_APP_API_URL}/files/${file._id}`, {
      headers: {
        authorization: `Bearer ${credentials}`,
      },
    })
      .then(response => response.blob())
      .then(data => fileDownload(data, file.filename))
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, credentials]);
};
