import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { removeCredentials } from 'actions/credentials';
import Button from 'components/button';

export default () => {
  // Redux
  const dispatch = useDispatch();

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle disconnect
  const handleDisconnect = useCallback(() => dispatch(removeCredentials()), [dispatch]);

  return (
    <>
      <div className="py-6 md:py-12" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold text-center md:text-2xl">
            Mon compte
          </h2>
        </div>
      </div>
      <div className="fade-in container my-8 px-4 min-h-screen md:my-12 md:mx-auto 2xl:px-0">
        <div className="md:grid md:gap-12 md:grid-cols-12">
          <div className="col-span-5 mb-8 md:mb-0">
            <div className="flex items-center">
              <h2 className="text-xl font-semibold">
                Administrateur
              </h2>
            </div>
            <div className="flex flex-col items-center mt-4 md:mt-12 md:flex-row">
              <Button icon="edit" to="/me/password" className="w-full text-center mb-4 md:w-auto md:text-right md:mb-0 md:mr-4">Modifier le mot de passe</Button>
              <Button icon="log-out" onClick={handleDisconnect} className="w-full text-center md:w-auto md:text-right">Se déconnecter</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
