import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from './icon';

type Props = {
  type?: string,
  className?: string,
  containerClassName?: string,
  prefix?: string | React.Element,
  suffix?: string | React.Element,
};

export default ({
  className,
  containerClassName,
  prefix = null,
  suffix = null,
  type = 'text',
  ...rest
}: Props) => {
  // Local state
  const [hidden, setHidden] = useState(true);

  // Password input ?
  if (type === 'password') {
    return (
      <div className={classNames('flex items-center relative', containerClassName)}>
        {prefix && <Icon name={prefix} className="text-lg text-gray-500 absolute ml-3" />}
        <input
          className={classNames(
            'appearance-none bg-white border border-gray-200 rounded w-full px-4 py-3 text-gray-700 leading-tight focus:border-blue-500 focus:form-element-shadow',
            { 'pl-10': prefix, 'pr-10': suffix },
            className,
          )}
          type={hidden ? 'password' : 'text'}
          {...rest}
        />
        <Icon
          name={hidden ? 'eye-off' : 'eye'}
          className="text-lg text-blue-600 absolute right-0 mr-3"
          onClick={() => setHidden(!hidden)}
        />
      </div>
    );
  }

  return (
    <div className={classNames('flex items-center relative', containerClassName)}>
      {prefix && <Icon name={prefix} className="text-lg text-gray-500 absolute ml-3" />}
      <input
        className={classNames(
          'appearance-none bg-white border border-gray-200 rounded w-full px-4 py-3 text-gray-700 leading-tight focus:border-blue-500 focus:form-element-shadow',
          { 'pl-10': prefix, 'pr-10': suffix },
          className,
        )}
        type={type}
        {...rest}
      />
      {suffix && (
        typeof suffix === 'string'
          ? <Icon name={suffix} className="text-lg text-gray-500 absolute right-0 mr-3" />
          : <span className="absolute right-0 mr-3">{suffix}</span>)}
    </div>
  );
};
