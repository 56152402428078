export default (state = 'card', { type, payload }) => {
  switch (type) {
    // Set display
    case 'display:store':
      return payload;

    default:
      return state;
  }
};
