import { useState, useEffect } from 'react';

export default (value, delay) => {
  // Local state
  const [debounced, setDebounced] = useState(value);

  // Update value
  useEffect(() => {
    // Update debounced value after delay
    const timer = setTimeout(() => setDebounced(value), delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => clearTimeout(timer);
  }, [value, delay]);

  return debounced;
};
