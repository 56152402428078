import React from 'react';
import { useSelector } from 'react-redux';
import User from './user';
import Admin from './admin';

export default function Me() {
  // Redux
  const role = useSelector(state => state.role);

  // Admin ?
  if (role === 'admin') {
    return <Admin />;
  }

  return <User />;
}
