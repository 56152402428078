import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from 'actions/notification';
import useUser from './user';

// me hook
export default () => {
  const dispatch = useDispatch();

  try {
    const { sub } = useSelector(state => JSON.parse(atob((state.credentials || '').split('.')[1])));
    return useUser(sub);

  // ... error
  } catch (error) {
    dispatch(showNotification('Erreur durant le chargement de la session', 'error'));
    return null;
  }
};
