import React, { useState, useEffect } from 'react';
import { Link, navigate } from '@reach/router';
import useFetch from 'hooks/fetch';
import useDownloadFile from 'hooks/download';
import ErrorPage from 'pages/error';
import Delay from 'components/delay';
import Dropdown from 'components/dropdown';
import Icon from 'components/icon';
import IfAdmin from 'components/if-admin';
import Duration from './components/duration';
import Images from './components/images';

type Props = {
  id: string,
};

export default function EventDetails({ id }: Props) {
  // Local state
  const [event, setEvent] = useState();

  // Redux
  const fetchjson = useFetch();

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  // Fetch event
  useEffect(() => {
    setEvent(null);
    fetchjson(`events/${id}`)
      .then(setEvent)
      .catch(({ message }) => setEvent(message));
  }, [id, fetchjson]);

  // Handle download attachment
  const handeDownload = useDownloadFile();

  if (!event || typeof event === 'string') {
    return (
      <>
        <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
          <div className="container mx-auto">
            <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
              <button type="button" onClick={() => navigate('/events')} className="leading-none mr-2">
                <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
              </button>
              Détails de l'évènement
            </h2>
          </div>
        </div>
        <div className="container my-8 px-4 min-h-screen md:my-12 md:mx-auto 2xl:px-0">
          {event
            ? (
              <ErrorPage message={event} />
            )
            : (
              <Delay>
                <Icon name="loader" className="animate-spin inline-block w-4 h-4" />
              </Delay>
            )}
        </div>
      </>
    );
  }

  // Separate documents & images
  const [documents, images] = (event.attachments || []).reduce((acc, item) => (
    item.mimetype.startsWith('image')
      ? [acc[0], acc[1].concat(item)]
      : [acc[0].concat(item), acc[1]]
  ), [[], []]);

  // Is past ?
  const is_past = new Date() > new Date(event.start_at);

  // Format content
  const content = event.content
    // Replace email by "mailto" link
    .replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/g, '<a href="mailto:$1">$1</a>')

    // Replace url https://, http://, ftp://
    // eslint-disable-next-line no-useless-escape
    .replace(/(?<![.*">])\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim, '<a href="$&">$&</a>')

    // www. without http:// or https://
    // eslint-disable-next-line no-useless-escape
    .replace(/(^|[^\/])(www\.[\S]+(\b|$))/gim, '$1<a href="http://$2">$2</a>')

    // Add target blank and class
    .replace(/<(a)([^>]+)>/gim, '<$1 target="_blank" class="text-blue-600"$2>');

  return (
    <>
      <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
            <button type="button" onClick={() => navigate('/events')} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
            </button>
            Détails de l'évènement
          </h2>
        </div>
      </div>
      <div className="fade-in container my-8 px-4 min-h-screen md:my-12 md:mx-auto 2xl:px-0">
        <div className="grid gap-16 grid-cols-1 md:gap-16 md:grid-cols-12">
          <div className="md:col-span-8">
            <h2 className="text-xl font-semibold mb-4 flex items-center justify-between md:text-3xl md:font-light">
              {event.title}
              <IfAdmin>
                <Dropdown label="Administrer" className="hidden md:block">
                  <Link to={`/events/edit/${event._id}`} className="flex items-center px-4 py-2 text-base hover:bg-red-900 hover:text-white">
                    <Icon name="edit" className="mr-2" />
                    Modifier l'évènement
                  </Link>
                  <Link to={`/events/delete/${event._id}`} className="flex items-center px-4 py-2 text-base hover:bg-red-900 hover:text-white">
                    <Icon name="trash-2" className="mr-2" />
                    Supprimer l'évènement
                  </Link>
                </Dropdown>
              </IfAdmin>
            </h2>
            <Duration
              start_date={event.start_at}
              start_time={event.start_time}
              end_date={event.end_at}
              end_time={event.end_time}
            />
            <p className="my-8 rich-text" dangerouslySetInnerHTML={{ __html: content }} />
            {!is_past && event.place && event.place.name && (
              <div className="mb-8">
                <p className="text-gray-500 italic flex items-center mb-2">
                  <Icon name="map-pin" className="mr-2" />
                  Lieu de l'évènement
                </p>
                <div className="my-1 ml-8">
                  {event.place.name}
                  <br />
                  {event.place.street}
                  <br />
                  {`${event.place.postcode || ''} ${event.place.city || ''}`}
                  <a
                    href={`https://maps.google.com/?q=${event.place.name || ''} ${event.place.street || ''} ${event.place.postcode || ''} ${event.place.city || ''}`}
                    target="_blank"
                    rel="noreferrer"
                    className="mt-2 text-blue-600 flex items-center"
                  >
                    <Icon name="map-pin" className="mr-1" />
                    Localiser sur la carte
                  </a>
                </div>
              </div>
            )}
            {documents.length > 0 && (
              <div className="mb-8">
                <p className="text-gray-500 italic flex items-center mb-2">
                  <Icon name="paperclip" className="mr-2" />
                  Pièces jointes :
                </p>
                {documents.map(item => (
                  <p key={item._id} className="my-1 ml-8">
                    <button type="button" className="text-blue-600" onClick={() => handeDownload(item)}>{item.filename}</button>
                  </p>
                ))}
              </div>
            )}
            {images.length > 0 && (
              <div className="mb-8">
                <p className="text-gray-500 italic flex items-center mb-2">
                  <Icon name="image" className="mr-2" />
                  Images de l'évènement :
                </p>
                <Images files={images} />
              </div>
            )}
            {!is_past && event.link && (
              <div className="flex justify-center">
                <a href={event.link} target="_blank" rel="noreferrer" className="py-3 px-4 text-base text-gray-600 leading-tight bg-white rounded border border-gray-200 hover:shadow-lg hover:border-gray-300 md:ml-8">
                  <Icon name="check-circle" className="relative top-x mr-2" />
                  Je souhaite participer
                </a>
              </div>
            )}
          </div>
          <div className="md:col-span-4">
            <div className="text-red-900 font-semibold border-b border-red-900 pb-2 mb-6">
              Autres évènements à venir
            </div>
            {event.others && event.others.length
              ? event.others.map(item => (
                <>
                  <Link to={`/events/view/${item._id}`}>
                    <p className="truncate mb-2">
                      {item.title}
                    </p>
                    <p className="flex items-center text-sm">
                      <Icon name="calendar" className="text-blue-600 mr-2" />
                      {new Date(item.start_at).toLocaleDateString()}
                    </p>
                  </Link>
                  <hr className="h-px border-none bg-teal-100 mx-0 my-4" />
                </>
              ))
              : <p className="italic">Aucun évènement</p>}
          </div>
        </div>
      </div>
    </>
  );
}
