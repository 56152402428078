import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from 'pages/app';
import store from './store';
import './styles.css';

// DEV MONITORING
/* eslint-disable import/no-extraneous-dependencies, global-require */
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
/* eslint-enable import/no-extraneous-dependencies, global-require */

render((
  <Provider store={store}>
    <App />
  </Provider>
), document.getElementById('root'));
