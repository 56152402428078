import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchjson from '@jollie/fetchjson';
import { removeCredentials } from 'actions/credentials';

// fetch hook
export default () => {
  // Redux
  const dispatch = useDispatch();
  const credentials = useSelector(state => state.credentials);

  // Init fetch
  return useMemo(() => (endpoint, data, params = {}) => {
    // Init headers
    const init = { hostname: process.env.REACT_APP_API_URL };

    // Has credentials
    if (credentials) {
      init.authorization = `Bearer ${credentials}`;
    }

    // Return native fetch
    return fetchjson(endpoint, data, { ...init, ...params })
      // Catch Unauthorized
      .catch(error => {
        if (error.code === 401) {
          dispatch(removeCredentials());
        }

        throw error;
      });
  }, [dispatch, credentials]);
};
