import React from 'react';
import useEvents from 'hooks/events';
import Button from 'components/button';
import IfAdmin from 'components/if-admin';
import UpcomingEvents from './components/upcoming-events';
import PastEvents from './components/past-events';

export default function EventsHome() {
  // Redux
  const [upcoming_events, past_events] = useEvents();

  return (
    <>
      <div className="py-6 md:py-20" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-white font-semibold text-center md:text-xl">
            Évènements & Actus
            <small className="block text-sm font-normal md:text-base">
              Activités de l'AFDU organisées de façon autonome ou en partenariat, dernières nouvelles...
            </small>
          </h2>
        </div>
      </div>
      <div className="container my-6 px-4 min-h-screen md:my-8 md:mx-auto 2xl:px-0">
        <h2 className="flex items-center justify-between text-3xl font-light mb-8">
          Évènements à venir
          <IfAdmin>
            <Button icon="plus" to="/events/create" className="hidden md:block">Ajouter un évènement</Button>
          </IfAdmin>
        </h2>
        <UpcomingEvents data={upcoming_events} />
        <PastEvents data={past_events} />
      </div>
    </>
  );
}
