import React from 'react';
import TextInput from 'components/text-input';
import Icon from 'components/icon';

type Props = {
  query: string,
  onChange: () => null,
};

export default ({ query, onChange, ...rest }: Props) => (
  <TextInput
    value={query}
    onChange={event => onChange(event.target.value)}
    placeholder="Rechercher un fichier ..."
    suffix={query
      ? (
        <button type="button" onClick={() => onChange('')}>
          <Icon name="x" className="text-red-900" />
        </button>
      )
      : 'search'}
    containerClassName="w-full md:w-96"
    {...rest}
  />
);
