import React, { useState } from 'react';

type Props = {
  files: Array,
};

export default function Images({ files }: Props) {
  // Local state
  const [current, setCurrent] = useState(null);

  return (
    <>
      <div className="flex items-center space-x-2">
        {files.slice(0, 3).map((item, i) => (
          <div key={item._id} className="w-1/5 bg-white p-1 shadow rounded">
            <img src={item.src} className="cursor-pointer object-cover w-full h-24" onClick={() => setCurrent(i)} />
          </div>
        ))}
        {files.length > 3 && <span className="italic pl-4">{`... et ${files.length - 3} autre${files.length - 3 > 1 ? 's' : ''}`}</span>}
      </div>
      {current !== null && (
        <div className="fixed inset-0 bg-black fade-in z-50">
          {files.map((item, i) => (
            <div
              key={item._id}
              className={`absolute inset-0 w-screen h-screen flex items-center justify-center transition-opacity ease-in-out duration-500 ${current === i ? ' opacity-1' : 'opacity-0'}`}
            >
              <img src={item.src} className="object-contain w-full h-full" onClick={() => setCurrent(i)} />
            </div>
          ))}
          <button
            type="button"
            onClick={() => setCurrent(null)}
            className="fixed top-0 right-0  bg-red-900 text-white text-xl w-12 h-12 md:w-16 md:h-16 flex items-center justify-center"
          >
            &#x2715;
          </button>
          {files.length > 1 && (
            <>
              <button
                type="button"
                onClick={() => setCurrent(current - 1 < 0 ? files.length - 1 : current - 1)}
                className="fixed inset-y-1/2 -mt-6 md:-mt-8 left-0 bg-red-900 text-white text-xl w-12 h-12 md:w-16 md:h-16 flex items-center justify-center"
              >
                &#x276E;
              </button>
              <button
                type="button"
                onClick={() => setCurrent(current + 1 >= files.length ? 0 : current + 1)}
                className="fixed inset-y-1/2 -mt-6 md:-mt-8 right-0 bg-red-900 text-white text-xl w-12 h-12 md:w-16 md:h-16 flex items-center justify-center"
              >
                &#x276F;
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}
