import React from 'react';
import Icon from 'components/icon';

type Props = {
  name: string,
  icon?: string,
  text?: string,
  active: string | null,
  onClick: () => void,
};

export default ({
  name,
  icon,
  text,
  active,
  onClick,
}: Props) => (
  <button
    type="button"
    className="px-2 py-1 rounded border border-transparent hover:border-gray-200"
    onClick={() => onClick(name)}
  >
    {icon
      ? (
        <Icon
          name={icon}
          className={active ? 'text-black' : 'text-gray-300'}
        />
      )
      : <span className={active ? 'text-black font-semibold' : 'text-gray-300'}>{text}</span>}
  </button>
);
