import React from 'react';
import Event from './event';

type Props = {
  data: [] | null,
};

export default ({ data }: Props) => {
  // Loading ?
  if (!Array.isArray(data)) {
    return (
      <div className="flex items-center justify-center">Chargement en cours ...</div>
    );
  }

  // No event ?
  if (!data.length) {
    return <div className="flex items-center justify-center">Aucun évènement à venir ...</div>;
  }

  return (
    <div className="grid gap-4 grid-cols-1 md:gap-8 md:grid-cols-3">
      {data.map(item => <Event key={item._id} data={item} />)}
    </div>
  );
};
