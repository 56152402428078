import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import useFetch from 'hooks/fetch';
import { storeCredentials } from 'actions/credentials';
import { showNotification } from 'actions/notification';
import Form from 'components/form';
import TextInput from 'components/text-input';
import Button from 'components/button';

export default function SignIn() {
  // Local state
  const [hidden, setHidden] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const credentials = useSelector(state => state.credentials);
  const fetchjson = useFetch();

  // Redirect to home
  useEffect(() => {
    if (credentials) {
      // has redirect query ?
      const redirect = new URLSearchParams(window.location.search).get('redirect');

      // ... redirect
      navigate(redirect ? atob(redirect) : '/');
    }
  }, [credentials]);

  // Handle submit
  const handleSubmit = useCallback(data => {
    fetchjson('POST auth', data)
      .then(payload => dispatch(storeCredentials(payload)))
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, fetchjson]);

  // Handle lost password
  const handleLostPassword = useCallback(data => {
    fetchjson('POST messages/lost-password', data)
      .then(({ message }) => {
        setHidden(true);
        dispatch(showNotification(message));
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, fetchjson]);

  return (
    <div className="flex flex-col justify-center min-h-screen text-gray-900" style={{ background: 'linear-gradient(#cd132d 0%, #cd132d 50%, #f2f3f4 50%, #f2f3f4 100%)' }}>
      <header className="mb-12 -mt-10 md:mb-16 md:-mt-10 xl:-mt-40">
        <img src="/logo-white.png" alt="AFDU..." className="w-48 md:w-72 mx-auto" />
      </header>
      <div className="bg-white mx-4 p-8 rounded shadow-lg md:w-2/3 md:mx-auto md:px-20 md:py-12 xl:w-2/5 2xl:w-1/3">
        <h2 className="text-lg text-red-900 font-semibold">Espace membre</h2>
        <hr className="h-px border-none bg-red-900 mx-0 mt-2 mb-8" />
        {hidden
          ? (
            <Form onSubmit={handleSubmit} className="fade-in">
              <div className="mb-4">
                Adresse email :
                <TextInput name="email" type="email" prefix="at-sign" required />
              </div>
              <div className="mb-4">
                Mot de passe :
                <TextInput name="password" type="password" prefix="lock" required />
              </div>
              <div className="mt-8 md:flex justify-between">
                <div className="flex items-center justify-center md:order-last">
                  <Button type="submit">Se connecter</Button>
                </div>
                <div className="mt-4 flex items-center md:mt-0">
                  <button type="button" className="text-blue-600" onClick={() => setHidden(false)}>Problème pour se connecter ?</button>
                </div>
              </div>
            </Form>
          )
          : (
            <Form onSubmit={handleLostPassword} className="fade-in">
              <p className="bg-blue-100 border border-blue-300 text-blue-900 rounded mb-6 px-4 py-2">
                Saisir votre adresse email, un message vous sera envoyé avec les instructions pour vous connecter.
              </p>
              <div className="mb-4">
                Adresse email :
                <TextInput name="email" type="email" prefix="at-sign" required />
              </div>
              <div className="mt-8 md:flex justify-between">
                <div className="flex items-center justify-center md:order-last">
                  <Button type="submit">Valider</Button>
                </div>
                <div className="mt-4 flex items-center md:mt-0">
                  <button type="button" className="text-blue-600" onClick={() => setHidden(true)}>Revenir</button>
                </div>
              </div>
            </Form>
          )}
      </div>
    </div>
  );
}
