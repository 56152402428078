import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import Options from './options';

type Props = {
  name: string,
  defaultValue: string | number | boolean | null,
  values: {},
  readOnly?: boolean,
  className?: string,
};

export default function Select({
  name,
  defaultValue,
  values,
  readOnly = false,
  className,
}: Props) {
  // Local state
  const [value, setValue] = useState();
  const [hidden, setHidden] = useState(true);

  // Set value
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // Hide
  const handleHide = useCallback(() => {
    setHidden(true);
    document.removeEventListener('click', handleHide);
  }, []);

  // Show
  const handleShow = useCallback(event => {
    event.stopPropagation();
    setHidden(false);
    document.addEventListener('click', handleHide);
  }, [handleHide]);

  // Remove listener on umount
  useEffect(() => () => document.removeEventListener('click', handleHide), [handleHide]);

  // Render element
  return (
    <div className={classNames('dropdown', className)}>
      <input type="hidden" name={name} value={value} />
      <button type="button" onClick={handleShow} className="py-3 px-4 text-base text-gray-600 leading-tight bg-white rounded border border-gray-200 hover:shadow-lg hover:border-gray-300">
        {readOnly ? values[defaultValue] : values[value]}
        <Icon name="chevron-down" className="ml-1" />
      </button>
      {!hidden && (
        <Options values={values} onChange={setValue} />
      )}
    </div>
  );
}
