import React from 'react';

type Props = {
  className?: string,
};

export default ({ className, ...rest }: Props) => (
  <textarea
    className="appearance-none bg-white border border-gray-200 rounded w-full px-4 py-3 text-gray-700 leading-tight focus:border-blue-500 focus:form-element-shadow"
    {...rest}
  />
);
