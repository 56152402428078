import React from 'react';
import { Link } from '@reach/router';

type Props = {
  data: [],
};

export default function List({ data }: Props) {
  return (
    <div>
      <div className="flex items-center text-gray-400 pb-2 border-b border-teal-200 mb-2">
        <div className="w-5/12 flex items-center">
          <span className="w-1/2 px-2">Nom & Prénom</span>
          <span className="w-1/2 px-2">Fonction</span>
        </div>
        <span className="w-3/12 px-2">Organization</span>
        <span className="w-2/12 px-2">Email</span>
        <span className="w-2/12 px-2">Téléphone</span>
      </div>
      {data.map(item => (
        <Link
          key={item._id}
          to={`/members/view/${item._id}`}
          className="flex items-center py-2 py-4 border-b border-teal-100 text-sm hover:bg-teal-100"
        >
          <div className="w-5/12 flex items-center">
            <span className="w-1/2 truncate px-2">{`${item.lastname} ${item.firstname}`}</span>
            <span className="w-1/2 truncate px-2">{item.job || item.job2 || '-'}</span>
          </div>
          <span className="w-3/12 truncate px-2">{item.organization || '-'}</span>
          <span className="w-2/12 truncate px-2" title={item.email || item.assistant_email}>{item.email || item.assistant_email || '-'}</span>
          <span className="w-2/12 truncate px-2" title={item.phone || item.phone_pro || item.assistant_phone}>{item.phone || item.phone_pro || item.assistant_phone || '-'}</span>
        </Link>
      ))}
    </div>
  );
}
