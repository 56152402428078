import React, { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { showNotification } from 'actions/notification';
import Button from 'components/button';

type Props = {
  files: [],
  onChange: () => null,
  className: string | {},
};

export default ({ files = [], onChange, className = '' }: Props) => {
  // Local state
  const [loading, setLoading] = useState(false);

  // Input file
  const fileref = useRef(null);

  // Redux
  const dispatch = useDispatch();
  const credentials = useSelector(state => state.credentials, shallowEqual);

  // Set file on change
  const handleChange = useCallback(() => {
    // No file ?
    if (!fileref.current?.files?.length) {
      return dispatch(showNotification('Aucun fichier sélectionné'));
    }

    // Get file
    const file = fileref.current.files[0];

    // too large ... ?
    if (file.size > 10485760) {
      return dispatch(showNotification('Le fichier est trop volumineux (> 10 Mo)'));
    }

    // Init data
    const formData = new FormData();
    formData.append('file', file);

    // Loading ...
    setLoading(true);

    // Upload
    return fetch(`${process.env.REACT_APP_API_URL}/files`, {
      method: 'POST',
      body: formData,
      headers: {
        authorization: `Bearer ${credentials}`,
      },
    })
      // Get response
      .then(response => response.json())

      // Append file
      .then(payload => onChange(files.concat(payload)))

      // ... Error
      .catch(({ message }) => dispatch(showNotification(message)))

      // Hide loading
      .finally(() => {
        fileref.current.value = null;
        setLoading(false);
      });
  }, [files, onChange, fileref, dispatch, credentials]);

  return (
    <div className={className}>
      <input type="file" ref={fileref} onChange={handleChange} className="hidden" />
      <Button icon="upload-cloud" onClick={() => fileref.current.click()}>Téléverser un fichier</Button>
      {files.map(file => (
        <div key={file._id} className="flex items-center my-2">
          {file.filename}
          <button
            type="button"
            onClick={() => onChange(files.filter(item => item._id !== file._id))}
            className="text-blue-600 ml-4"
          >
            Supprimer
          </button>
        </div>
      ))}
      {loading === true && <p>Téléversement en cours ...</p>}
    </div>
  );
};
