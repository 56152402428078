import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import Icon from './icon';

type Props = {
  type?: 'button' | 'submit',
  icon?: string,
  to?: string,
  className?: string,
};

/* eslint-disable react/button-has-type */
export default ({
  type = 'button',
  icon,
  to,
  className,
  children,
  ...rest
}: Props) => {
  if (to) {
    return (
      <Link
        to={to}
        className={classNames(
          'py-3 px-4 text-base text-gray-600 leading-tight bg-white rounded border border-gray-200 hover:shadow-lg hover:border-gray-300',
          className,
        )}
        {...rest}
      >
        {icon && <Icon name={icon} className={classNames('relative top-x mr-2', icon === 'loader' && 'inline-block animate-spin')} />}
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={classNames(
        'py-3 px-4 text-base text-gray-600 leading-tight bg-white rounded border border-gray-200 hover:shadow-lg hover:border-gray-300',
        className,
      )}
      {...rest}
    >
      {icon && <Icon name={icon} className={classNames('relative top-x mr-2', icon === 'loader' && 'inline-block animate-spin')} />}
      {children}
    </button>
  );
};
