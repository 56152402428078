import { useState, useEffect } from 'react';

export default ({ duration = 300, children }) => {
  // Local state
  const [hidden, setHidden] = useState(true);

  // Delay
  useEffect(() => {
    const timer = setTimeout(() => setHidden(false), duration);
    return () => clearTimeout(timer);
  }, [duration]);

  if (hidden) {
    return null;
  }

  return children;
};
