export default (state = null, { type, payload }) => {
  switch (type) {
    // Store
    case 'credentials:store':
      localStorage.setItem('credentials', payload.token);
      return payload.token;

    // Remove
    case 'credentials:remove':
      localStorage.removeItem('credentials');
      return null;

    default:
      return state;
  }
};
