import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { storeEvents } from 'actions/events';
import { showNotification } from 'actions/notification';
import useFetch from './fetch';

// Events hook
export default () => {
  // Redux
  const dispatch = useDispatch();
  const events = useSelector(state => state.events, shallowEqual);
  const fetchjson = useFetch();

  // Fetch users
  useEffect(() => {
    if (!events.length) {
      fetchjson('events')
        .then(payload => dispatch(storeEvents(payload)))
        .catch(({ message }) => dispatch(showNotification(message, 'error')));
    }
  }, [events, dispatch, fetchjson]);

  // Return events ... or empty data
  return events;
};
