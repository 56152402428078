import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import reducers from 'reducers';

// Init middlewares
const middlewares = [];

// Debug ?
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger({ collapsed: true }));
}

// Get credentials
const credentials = localStorage.getItem('credentials');
let role = null;

if (credentials) {
  try {
    role = JSON.parse(atob(credentials.split('.')[1]))?.role;
  } catch (error) {
  }
}

// Create store
const store = createStore(reducers, { credentials, role }, applyMiddleware(...middlewares));

// Return store
export default store;
