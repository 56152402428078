import React from 'react';
import { Link } from '@reach/router';
import IfAdmin from 'components/if-admin';
import Icon from 'components/icon';

type Props = {
  user: {},
};

/* eslint-disable react/prop-types */
export default ({ user }: Props) => (
  <Link to={`/members/view/${user._id}`} className="bg-white rounded px-6 py-4 hover:shadow-lg flex flex-col">
    <p className="flex items-center justify-between text-lg text-red-900">
      {`${user.lastname} ${user.firstname}`}
      {new Date() > new Date(user.expires_at)
        ? (
          <IfAdmin>
            <span className="bg-orange-700 rounded-full px-3 py-1 text-xs text-white">Impayée</span>
          </IfAdmin>
        )
        : null}
    </p>
    <p className="truncate font-semibold leading-tight">{user.organization || '-'}</p>
    {user.job
      ? <p className="mb-4 leading-tight text-sm italic truncate">{user.job}</p>
      : <p className="mb-4 leading-tight text-sm">&nbsp;</p>}
    <div className="flex items-center text-sm text-gray-600 mt-6 mb-1">
      <Icon name="at-sign" className="mr-2" />
      <p className="truncate">{user.email || user.assistant_email}</p>
    </div>
    {user.phone || user.phone_pro || user.assistant_phone
      ? (
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <Icon name="phone" className="mr-2" />
          <p className="truncate">{user.phone || user.phone_pro || user.assistant_phone}</p>
        </div>
      )
      : null}
    {user.address?.city
      ? (
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <Icon name="map-pin" className="mr-2" />
          <p className="truncate">{user.address.city}</p>
        </div>
      )
      : null}
  </Link>
);
