import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { removeEvents } from 'actions/events';
import { showNotification } from 'actions/notification';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';

type Props = {
  id: string,
};

export default function DeleteForm({ id }: Props) {
  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Handle submit
  const handleSubmit = useCallback(() => {
    fetchjson(`DELETE events/${id}`)
      .then(() => {
        dispatch(showNotification('Évènement supprimé avec succès !', 'success'));
        dispatch(removeEvents());
        navigate('/events');
      })
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [id, dispatch, fetchjson]);

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <button type="button" onClick={() => navigate(`/events/view/${id}`)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </button>
            Supprimer l'évènement
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <div className="text-xl text-red-900">
          Êtes-vous sûr de supprimer définitivement cet évènement ?
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="my-12 flex items-center">
            <button type="button" onClick={() => navigate(`/events/view/${id}`)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="trash-2" type="submit">Confirmer la suppression</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
