import React from 'react';

/* eslint-disable react/no-array-index-key */
export default () => (
  <div className="fade-in animate-pulse">
    {new Array(4).fill(null).map((nop, item) => (
      <div key={`placeholder-table-${item}`} className="flex w-full items-center px-2 py-2 py-4 border-b border-teal-100 text-sm">
        <div className="flex w-4/5 md:w-1/2">
          <div className="flex items-center w-1/2">
            <span className="flex h-4 w-4 rounded-full bg-teal-100 mr-2" />
            <span className="flex h-4 w-1/2 rounded bg-teal-100" />
          </div>
        </div>
        <div className="hidden md:flex md:w-1/4">
          <span className="flex h-4 w-1/2 rounded bg-teal-100" />
        </div>
        <div className="hidden md:flex md:w-1/4 md:block">
          <span className="flex h-4 w-1/2 rounded bg-teal-100" />
        </div>
      </div>
    ))}
  </div>
);
