import React from 'react';
import { Link } from '@reach/router';
import Icon from 'components/icon';
import MIMETYPES from './mimetypes';

function isFolder(data) {
  return data.mimeType === 'application/vnd.google-apps.folder';
}

/* eslint-disable react/prop-types */
export default ({ data }) => {
  // Folder ?
  if (isFolder(data)) {
    return (
      <Link
        to={`/filemanager/${data.id}`}
        className="flex w-full items-center px-2 py-2 py-4 border-b border-teal-100 text-sm hover:bg-teal-100"
      >
        <span className="flex items-center w-4/5 md:w-1/2">
          {data.iconLink
            ? <img src={data.iconLink} className="w-4 h-4 mr-2" />
            : <Icon name="folder" className="mr-2 text-yellow-700" />}
          {data.name}
        </span>
        <span className="hidden md:flex md:w-1/4 md:block">Dossier</span>
        <span className="hidden md:flex md:w-1/4 md:block">
          {`Le ${new Date(data.modifiedTime).toLocaleString()}`}
        </span>
      </Link>
    );
  }

  // ... file
  return (
    <a
      href={`${process.env.REACT_APP_API_URL}/filemanager/${data.id}?mimeType=${data.mimeType}&name=${data.name}`}
      target="_blank"
      rel="noreferrer"
      className="flex w-full items-center px-2 py-2 py-4 border-b border-teal-100 text-sm hover:bg-teal-100"
    >
      <span className="flex items-center w-4/5 md:w-1/2">
        {data.iconLink
          ? <img src={data.iconLink} className="w-4 h-4 mr-2" />
          : <Icon name="file" className="mr-2" />}
        {data.name}
      </span>
      <span className="hidden md:flex md:w-1/4 md:block">{MIMETYPES[data.mimeType] || 'Fichier'}</span>
      <span className="hidden md:flex md:w-1/4 md:block">
        {`Le ${new Date(data.modifiedTime).toLocaleString()}`}
      </span>
    </a>
  );
};
