export default (state = {}, { type, payload }) => {
  switch (type) {
    case 'notification:show':
      return { status: 'show', ...payload };

    case 'notification:hide':
      return state.status === 'show' ? { ...state, status: 'hide' } : state;

    case 'notification:destroy':
      return { status: 'destroy' };

    default:
      return state;
  }
};
