import React from 'react';
import { Link } from '@reach/router';

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
export default ({ activatedPath = null, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent, location }) => (
      isCurrent || (activatedPath && location.pathname.startsWith(activatedPath))
        ? { style: { color: '#333', fontWeight: '600', borderBottom: '2px solid #cd132d' } }
        : null
    )}
  />
);
