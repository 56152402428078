import React from 'react';
import { Link } from '@reach/router';
import Icon from 'components/icon';

function getFullPath(tree, current, data = [], i = 0) {
  const item = tree[current];

  if (item && item.parent && i < 20) {
    return getFullPath(tree, item.parent, data.concat(tree[item.parent]), i + 1);
  }

  return data.reverse();
}

type Props = {
  tree: {},
  current: string,
};

export default ({ tree, current }: Props) => {
  if (current === null) {
    return (
      <h2 className="order-last mt-6 flex items-center text-sm text-gray-700 md:text-base md:order-first md:mt-0">
        Résultat de la recherche
      </h2>
    );
  }

  return (
    <h2 className="order-last mt-6 flex items-center text-sm text-gray-700 md:text-base md:order-first md:mt-0">
      <Link to="/filemanager" className="text-blue-600">Accueil</Link>
      {getFullPath(tree, current).map(({ id = '', name = '' }) => (
        <div key={id}>
          <Icon name="chevron-right" className="text-red-900 mx-1 md:mx-2" />
          <Link type="Link" to={`/filemanager/${id}`} className="text-blue-600">{name}</Link>
        </div>
      ))}
      {current && (
        <div className="truncate">
          <Icon name="chevron-right" className="text-red-900 mx-1 md:mx-2" />
          <span>{tree[current]?.name || '...'}</span>
        </div>
      )}
    </h2>
  );
};
