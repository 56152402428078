import React, { useEffect, useState } from 'react';
import Button from '../button';
import TextInput from '../text-input';

type Props = {
  initialValue: string,
  hidden: boolean,
  onSubmit: () => void,
  onDismiss: () => void,
};

export default ({
  initialValue,
  hidden,
  onSubmit,
  onDismiss,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Hidden ?
  if (hidden) {
    return null;
  }

  return (
    <div className="bg-black bg-opacity-30 fixed flex inset-0 items-center justify-center z-40">
      <div className="bg-white mb-32 px-8 py-6 rounded-lg">
        <div className="mb-6 text-gray-800 text-lg">Saisir une URL</div>
        <div className="mb-8">
          <div className="text-sm text-gray-700 mb-1">URL</div>
          <TextInput value={value} onChange={event => setValue(event.target.value)} placeholder="https://" />
          <small>laisser vide pour supprimer le lien</small>
        </div>
        <div className="flex justify-end space-x-4">
          <Button onClick={() => onDismiss()}>Annuler</Button>
          <Button onClick={() => onSubmit(value)}>Valider</Button>
        </div>
      </div>
    </div>
  );
};
