import React from 'react';
import Event from './event';
import MONTHS from '../../months';

type Props = {
  data: [] | null,
};

export default ({ data }: Props) => {
  // Loading ?
  if (!Array.isArray(data)) {
    return (
      <>
        <hr className="h-px border-none bg-teal-100 mx-0 my-12" />
        <h2 className="text-3xl text-gray-700 font-light italic mb-8">Évènements passés</h2>
        <div className="flex items-center justify-center">Chargement en cours ...</div>
      </>
    );
  }

  // No event ?
  if (!data.length) {
    return null;
  }

  // Group events by month
  const by_month = data.reduce((acc, item) => {
    // Get date
    const date = new Date(item.start_at);
    const year = date.getFullYear();
    const label = `${MONTHS[date.getMonth()]} ${year}`;

    // New section ?
    if (!acc[acc.length - 1] || acc[acc.length - 1].label !== label) {
      acc.push({ label, year, events: [item] });

    // Append to current section
    } else {
      acc[acc.length - 1].events.push(item);
    }

    return acc;
  }, []);

  // Current year to display year heading
  let current_year;

  return (
    <>
      <hr className="h-px border-none bg-teal-100 mx-0 my-12" />
      <h2 className="text-3xl text-gray-700 font-light italic mb-8">Évènements passés</h2>
      {by_month.map(month => {
        // Heading year
        let heading_year;

        // Is different year ?
        if (month.year !== current_year) {
          current_year = month.year;
          heading_year = <span className="inline-block bg-red-900 text-3xl text-white font-light mb-8 px-2 py-1">{current_year}</span>;
        }

        return (
          <div key={month.label} className="mb-16">
            {heading_year}
            <h2 className="text-xl text-gray-600 font-semibold italic mb-4">{month.label}</h2>
            <div className="grid gap-4 grid-cols-1 md:gap-8 md:grid-cols-3">
              {month.events.map(item => <Event key={item._id} data={item} past />)}
            </div>
          </div>
        );
      })}
    </>
  );
};
