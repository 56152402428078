import React, { useState, useCallback, useRef } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { showNotification } from 'actions/notification';
import PageError from 'pages/error';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';

export default function Upload() {
  // Local state
  const [file, setFile] = useState(null);
  const fileref = useRef(null);

  // Redux
  const dispatch = useDispatch();
  const role = useSelector(state => state.role);
  const credentials = useSelector(state => state.credentials, shallowEqual);

  // Set file on change
  const handleChange = useCallback(() => {
    if (fileref.current?.files?.length) {
      setFile(fileref.current.files[0]);
    }
  }, [fileref]);

  // Handle submit
  const handleSubmit = useCallback(() => {
    // has file ... ?
    if (!file) {
      return dispatch(showNotification('Aucun fichier sélectionné'));
    }

    // too large ... ?
    if (file.size > 10485760) {
      return dispatch(showNotification('Le fichier est trop volumineux (> 10 Mo)'));
    }

    // xls ?
    if (!file.type.includes('application/vnd.ms-excel')
      && !file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      return dispatch(showNotification('Le format du fichier n\'est pas supporté'));
    }

    // Init data
    const formData = new FormData();
    formData.append('file', file);

    // Loading ...
    dispatch(showNotification({ message: 'Importation en cours ...', template: 'default', delay: 0 }));

    // Upload
    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'PUT',
      body: formData,
      headers: {
        authorization: `Bearer ${credentials}`,
      },
    })
      .then(response => {
        // Error ?
        if (!response.ok) {
          throw Error('Erreur durant le téléversement du fichier membres');
        }
      })
      .then(() => {
        dispatch(showNotification({ message: 'Membres importés avec succès !', template: 'success', delay: 3 }));
      })
      .catch(({ message }) => dispatch(showNotification({ message, delay: 3 })));

    return null;
  }, [file, credentials, dispatch]);

  // Not Admin ?
  if (role !== 'admin') {
    return <PageError message="Opération non permise" />;
  }

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </button>
            Importer un fichier XLS
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <div className="text-xl">
          Sélectionner un fichier au format XLS
        </div>
        <div className="my-4 w-3/4 px-4 py-3 rounded border border-orange-200 bg-orange-100 text-orange-900 text-sm flex items-center">
          <Icon name="alert-circle" className="mr-2" />
          <div>
            L'annuaire des membres sera remplacé par le contenu du fichier !
          </div>
        </div>
        <div className="my-4 w-3/4 px-4 py-3 rounded border border-blue-200 bg-blue-100 text-blue-900 text-sm">
          Le fichier doit respecter l'ordre des colonnes suivant (le nom des colonnes n'est pas important) :
          <ul className="mt-2 ml-12 list-disc font-mono text-xs">
            <li className="my-1">Cotis payée</li>
            <li className="my-1">Organisme</li>
            <li className="my-1">Titre</li>
            <li className="my-1">Nom</li>
            <li className="my-1">Prénom</li>
            <li className="my-1">Fonction</li>
            <li className="my-1">Autres fonctions</li>
            <li className="my-1">Adresse</li>
            <li className="my-1">Code postal</li>
            <li className="my-1">Ville</li>
            <li className="my-1">Téléphone</li>
            <li className="my-1">Portable / Tél. Professionnel</li>
            <li className="my-1">Email</li>
            <li className="my-1">Nom de l'assistant</li>
            <li className="my-1">Tél. assistante</li>
            <li className="my-1">Email assistante</li>
          </ul>
        </div>
        <Form onSubmit={handleSubmit}>
          <input type="file" ref={fileref} onChange={handleChange} className="hidden" />
          <div className="mb-4 flex items-center">
            <Button icon="file-plus" onClick={() => fileref.current.click()}>Sélectionner un fichier</Button>
            {file?.name
              ? <p className="ml-4 text-sm text-blue-600">{file.name}</p>
              : <p className="ml-4 text-sm text-gray-300">Format XLS - Poids max. 10 Mo</p>}
          </div>
          <div className="my-12 flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon="upload-cloud" type="submit">Importer</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
