import React from 'react';
import Card from './card';

type Props = {
  data: [],
};

export default ({ data }: Props) => (
  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
    {data.map(item => <Card key={item._id} user={item} />)}
  </div>
);
