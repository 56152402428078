import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import Icon from 'components/icon';
import MONTHS from '../../months';

type Props = {
  data: {},
  past?: boolean,
};

/* eslint-disable react/prop-types */
export default ({ data, past = false }: Props) => {
  // Dates
  const start = new Date(data.start_at);
  const end = new Date(data.end_at);

  // One duration ?
  const one_day = (
    start.getFullYear() === end.getFullYear()
    && start.getMonth() === end.getMonth()
    && start.getDate() === end.getDate()
  );

  return (
    <Link
      to={`/events/view/${data._id}`}
      className={classNames('bg-white rounded p-6 hover:shadow-lg', { 'bg-gray-100 border border-gray-200': past })}
    >
      <div className="mb-8 text-red-900 flex items-center">
        <div>
          <p className="text-4xl font-light leading-none">{start.getDate().toString().padStart(2, '0')}</p>
          <p className="leading-none">{MONTHS[start.getMonth()]}</p>
        </div>
        {!one_day && (
          <>
            <Icon name="arrow-right" className="mx-2 md:mx-4" />
            <div>
              <p className="text-4xl font-light leading-none">{end.getDate().toString().padStart(2, '0')}</p>
              <p className="leading-none">{MONTHS[end.getMonth()]}</p>
            </div>
          </>
        )}
      </div>
      <p className="font-semibold text-lg mb-4 truncate">{data.title}</p>
      {data.start_time
        ? (
          <div className="flex items-center text-sm text-gray-600 mb-1">
            <Icon name="clock" className="mr-2" />
            {data.start_time}
          </div>
        )
        : null}
      {data?.place?.city
        ? (
          <div className="flex items-center text-sm text-gray-600 mb-1">
            <Icon name="map-pin" className="mr-2" />
            {data.place.city}
          </div>
        )
        : null}
    </Link>
  );
};
