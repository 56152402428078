export default {
  'udio/aac': 'Audio AAC',
  'application/x-abiword': 'AbiWord',
  'video/x-msvideo': 'Audio Video Interleave',
  'application/vnd.amazon.ebook': 'EBook Amazon',
  'application/octet-stream': 'Fichier binaire',
  'image/bmp': 'Image Bitmap',
  'application/x-bzip': 'Archive BZip',
  'application/x-bzip2': 'Archive BZip2',
  'application/x-csh': 'Script C-Shell',
  'text/css': 'Cascading Style Sheets (CSS)',
  'text/csv': 'Comma-Separated Values (CSV)',
  'application/msword': 'Microsoft Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word',
  'application/vnd.ms-fontobject': 'Police MS Embedded OpenType',
  'application/epub+zip': 'Fichier Electronic publication (EPUB)',
  'image/gif': 'Image GIF',
  'text/html': 'Fichier HTML',
  'image/x-icon': 'Icône',
  'text/calendar': 'Élément iCalendar',
  'application/java-archive': 'Archive Java (JAR)',
  'image/jpeg': 'Image JPEG',
  'application/javascript': 'JavaScript',
  'application/json': 'Format JSON',
  'audio/midi': 'Musical Instrument Digital Interface',
  'video/mpeg': 'Vidéo MPEG',
  'application/vnd.apple.installer+xml': 'Paquet Apple Installer',
  'application/vnd.oasis.opendocument.presentation': 'Présentation OpenDocument',
  'application/vnd.oasis.opendocument.spreadsheet': 'Feuille de calcul OpenDocument',
  'application/vnd.oasis.opendocument.text': 'Document texte OpenDocument',
  'audio/ogg': 'Audio OGG',
  'video/ogg': 'Vidéo OGG',
  'application/ogg': 'OGG',
  'font/otf': 'Police OpenType',
  'image/png': 'Image PNG',
  'application/pdf': 'PDF (Portable Document Format)',
  'application/vnd.ms-powerpoint': 'Présentation Microsoft PowerPoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Présentation Microsoft PowerPoint (OpenXML)',
  'application/x-rar-compressed': 'Archive RAR',
  'application/rtf': 'Rich Text Format (RTF)',
  'application/x-sh': 'Script shell',
  'image/svg+xml': 'SVG',
  'application/x-shockwave-flash': 'Adobe Flash',
  'application/x-tar': 'Tape Archive (TAR)',
  'image/tiff': 'Image TIFF',
  'application/typescript': 'Typescript',
  'font/ttf': 'Police TrueType',
  'application/vnd.visio': 'Microsoft Visio',
  'audio/x-wav': 'Waveform Audio Format',
  'audio/webm': 'Audio WEBM',
  'video/webm': 'Vidéo WEBM',
  'image/webp': 'Image WEBP',
  'font/woff': 'Police Web Open Font Format (WOFF)',
  'font/woff2': 'Police Web Open Font Format (WOFF)',
  'application/xhtml+xml': 'XHTML',
  'application/vnd.ms-excel': 'Microsoft Excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Microsoft Excel',
  'application/xml': 'XML',
  'application/vnd.mozilla.xul+xml': 'XUL',
  'application/zip': 'Archive ZIP',
  'video/3gpp': 'Audio/vidéo 3GPP',
  'video/3gpp2': 'Audio/vidéo 3GPP2',
  'application/x-7z-compressed': '.7z archive 7-zip',
  'application/vnd.google-apps.audio': 'Google Audio',
  'application/vnd.google-apps.document': 'Google Document',
  'application/vnd.google-apps.drawing': 'Google Drawing',
  'application/vnd.google-apps.file': 'Google Drive',
  'application/vnd.google-apps.form': 'Google Form',
  'application/vnd.google-apps.photo': 'Google Photo',
  'application/vnd.google-apps.presentation': 'Google Slide',
  'application/vnd.google-apps.spreadsheet': 'Google Sheets',
  'application/vnd.google-apps.video': 'Google Vidéo',
};
