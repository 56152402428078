export default (state = [], { type, payload }) => {
  switch (type) {
    // Store events
    case 'events:store':
    {
      if (!Array.isArray(payload)) {
        console.warn(`Receive invalid events data of type ${typeof payload}`);
        return state;
      }

      // Get current date
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);

      // Separate upcoming and pasts events
      return payload.reduce((acc, item) => {
        if (new Date(item.end_at) >= now) {
          acc[0].unshift(item);
        } else {
          acc[1].push(item);
        }

        return acc;
      }, [[], []]);
    }

    // Remove events
    case 'events:remove':
      return [];

    default:
      return state;
  }
};
