import React, { useState, useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { showNotification } from 'actions/notification';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';

type Props = {
  id: string,
};

export default function SendPasswordForm({ id }: Props) {
  // Local state
  const [loading, setLoading] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Handle submit
  const handleSubmit = useCallback(() => {
    // Loading
    setLoading(true);

    // Fetch
    fetchjson('POST messages/send-password', { id })
      .then(() => {
        dispatch(showNotification('Emai envoyé au membre avec succès !', 'success'));
        navigate(`/members/view/${id}`);
      })
      .catch(({ message }) => dispatch(showNotification(message)))
      .finally(() => setLoading(false));
  }, [id, dispatch, fetchjson]);

  return (
    <>
      <div className="py-10" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-xl text-white font-semibold flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-3xl text-white" />
            </button>
            Envoyer les codes d'accès
          </h2>
        </div>
      </div>
      <div className="container mx-auto my-12">
        <div className="text-xl">
          Vous êtes sur le point de transmettre les codes d'accès à ce membre
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="my-12 flex items-center">
            <button type="button" onClick={() => navigate(-1)} className="text-blue-600">annuler</button>
            <span className="mx-2">ou</span>
            <Button icon={loading ? 'loader' : 'send'} type="submit" disabled={loading}>{loading ? 'Opération en cours ...' : 'Envoyer'}</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
