import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import useFetch from 'hooks/fetch';
import { showNotification } from 'actions/notification';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';
import TextArea from 'components/text-area';

export default function Request() {
  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();

  // Handle submit
  const handleSubmit = useCallback(data => {
    fetchjson('POST messages/contact', data)
      .then(() => dispatch(showNotification('Message envoyé avec succès !', 'success')))
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, fetchjson]);

  return (
    <>
      <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
            </button>
            Demande de modifications
          </h2>
        </div>
      </div>
      <div className="container my-8 px-4 md:my-12 md:mx-auto 2xl:px-0 md:w-1/3">
        <div className="px-4 py-2 rounded border border-blue-300 bg-blue-200 text-blue-900 text-sm mb-8">
          Saisir votre message en précisant les informations à mettre à jour.
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="text-sm text-gray-700 mb-1">Message</div>
          <TextArea name="message" rows={5} required />
          <div className="my-12 flex justify-center">
            <Button icon="send" type="submit">Envoyer la demande</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
