import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from '@reach/router';
import classNames from 'classnames';
import NavLink from './nav-link';
import Icon from './icon';

export default function Layout({ children }) {
  // Local state
  const [hidden, setHidden] = useState(true);

  // Redux
  const credentials = useSelector(state => state.credentials);

  // Close menu
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!hidden) {
      const handleHide = () => setHidden(true);
      document.addEventListener('click', handleHide);
      return () => document.removeEventListener('click', handleHide);
    }
  }, [hidden]);

  if (!credentials) {
    if (window.location.hostname === 'espacemembres.afdu.fr' && window.location.pathname !== 'signin') {
      return <Redirect to={`/signin?redirect=${btoa(window.location.pathname)}`} noThrow />;
    }

    return <Redirect to="signin" noThrow />;
  }

  return (
    <div className="fade-in relative min-h-screen bg-gray-100 text-gray-900">
      {hidden !== true && (
        <div className="absolute bg-white inset-0 z-50">
          <div className="px-4 py-3">
            <button
              type="button"
              onClick={() => setHidden(true)}
              className="menu-show"
            >
              <Icon name="arrow-right" className="text-red-900 text-3xl py-2" />
            </button>
            <div className="mt-4 ml-8">
              <Link to="/" activatedPath="/members" className="flex items-center font-semibold text-red-900 py-1 mb-2">
                Annuaire
              </Link>
              <Link to="/events" activatedPath="/events" className="flex items-center font-semibold text-red-900 py-1 mb-2">
                Évènements & Actus
              </Link>
              <Link to="/filemanager" activatedPath="/filemanager" className="flex items-center font-semibold text-red-900 py-1 mb-2">
                Documents administratifs et Historiques
              </Link>
              <Link to="/me" activatedPath="/me" className="flex items-center font-semibold text-red-900 py-1 mb-2">
                Mon compte
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white py-4">
        <header className="mx-4">
          <h1 className="flex items-center">
            <button
              type="button"
              onClick={() => setHidden(false)}
              className={classNames('md:hidden mr-4 absolute', {
                'menu-hide': hidden,
              })}
            >
              <Icon name="menu" className="text-red-900 text-3xl" />
            </button>
            <Link to="/" className="mx-auto">
              <img src="/logo.png" alt="AFDU..." className="h-8 mx-auto" />
              <small className="hidden md:block text-base font-normal text-gray-600 mt-2">
                <strong className="text-gray-900">A</strong>
                ssociation
                {' '}
                <strong className="text-gray-900">F</strong>
                rançaise du
                {' '}
                <strong className="text-gray-900">D</strong>
                éveloppement
                {' '}
                <strong className="text-gray-900">U</strong>
                rbain
                {' '}
              </small>
            </Link>
          </h1>
          <nav className="mt-8 md:block hidden">
            <ul className="flex justify-center items-end">
              <li className="mx-8">
                <NavLink to="/" activatedPath="/members/" className="flex items-center text-teal-300 py-1">
                  Annuaire
                </NavLink>
              </li>
              <li className="mx-8 flex items-center">
                <NavLink to="/events" activatedPath="/events/" className="flex items-center text-teal-300 py-1">
                  Évènements & Actus
                </NavLink>
              </li>
              <li className="mx-8 flex items-center">
                <NavLink to="/filemanager" activatedPath="/filemanager/" className="flex items-center text-teal-300 py-1">
                  Documents administratifs & Historiques
                </NavLink>
              </li>
              <li className="mx-8">
                <NavLink to="/me" activatedPath="/me/" className="flex items-center text-teal-300 py-1">
                  Mon compte
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      <div className="pb-16">
        {children}
      </div>
      <footer className="absolute bottom-0 right-0 text-xs mr-8 pb-4">
        &copy; Tous droits réservés 2021 -
        {' '}
        <a href="https://www.afdu.fr/" target="_blank" rel="noreferrer">AFDU</a>
      </footer>
    </div>
  );
}
