import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { storeRandomUsers } from 'actions/users';
import { showNotification } from 'actions/notification';
import useFetch from './fetch';

// Random members hook
export default () => {
  // Redux
  const dispatch = useDispatch();
  const users = useSelector(state => state.randomly, shallowEqual);
  const fetchjson = useFetch();

  // Fetch users
  useEffect(() => {
    if (!users) {
      fetchjson('users')
        .then(payload => dispatch(storeRandomUsers(payload)))
        .catch(({ message }) => dispatch(showNotification(message, 'error')));
    }
  }, [users, dispatch, fetchjson]);

  // Return users ... or empty data
  return users;
};
