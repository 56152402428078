import { useState, useEffect } from 'react';
import useFetch from './fetch';

// User hook
export default id => {
  // Local state
  const [user, setUser] = useState();

  // Redux
  const fetchjson = useFetch();

  // Fetch user
  useEffect(() => {
    fetchjson(`users/${id}`)
      .then(setUser)
      .catch(({ message }) => setUser(message));
  }, [id, fetchjson]);

  // Return user ... or empty data
  return user;
};
