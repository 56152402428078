export default (state = null, { type, payload }) => {
  switch (type) {
    // Store random users
    case 'random-users:store':
      if (!Array.isArray(payload)) {
        console.warn(`Receive invalid users data of type ${typeof payload}`);
        return state;
      }

      // Set users
      return payload;

    default:
      return state;
  }
};
