import React from 'react';
import { Link } from '@reach/router';

type Props = {
  message?: string,
};

export default function NotFound({ message = 'Cette page n\'existe pas ...' }: Props) {
  return (
    <div className="fade-in flex flex-col justify-center items-center mx-8 py-8">
      <img src="/error.png" />
      <div className="text-xl mt-16">
        {message}
      </div>
      <Link to="/" className="my-8 rounded border border-gray-300 px-6 py-2">
        Revenir à l'accueil
      </Link>
    </div>
  );
}
