import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import useFetch from 'hooks/fetch';
import useRandomUsers from 'hooks/random-users';
import { setDisplay } from 'actions/display';
import { showNotification } from 'actions/notification';
import Icon from 'components/icon';
import Users from './components/users';
import SearchInput from './components/search-input';
import Alphabet from './components/alphabet';

// Letters A to Z
const AZ = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

// Debounce timer
let debounce_timer;

function includesLetter(data, letter) {
  return data.findIndex(item => ((item.lastname || '').trim() || ' ')[0].toUpperCase() === letter) !== -1;
}

function filterUsers(users, letter) {
  if (!letter) {
    return users;
  }

  return users
    .filter(item => ((item.lastname || '').trim() || ' ')[0].toUpperCase() === letter)
    .sort((a, b) => (a.lastname || '').localeCompare((b.lastname || '')));
}

export default function MembersHome({ location }) {
  // Local state
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();
  const display = useSelector(state => state.display || 'card');
  const randomly = useRandomUsers();

  // Search users
  useEffect(() => {
    // Retrieve params
    const params = new URLSearchParams(location.search);
    const query = params.get('q');

    // Has query ?
    if (query) {
      // More than 2 chars ?
      if (query.split(' ').pop().length >= 2) {
        // Cler debounce timer
        clearTimeout(debounce_timer);

        // Debounce
        debounce_timer = setTimeout(() => {
          // Loading flag
          setLoading(true);

          // Fetch users
          fetchjson('users', { q: params.get('q') })
            .then(setUsers)
            .catch(({ message }) => dispatch(showNotification(message)))
            .finally(() => setLoading(false));
        }, 200);
      }

    // By letter ?
    } else if (params.get('letter')) {
      // Loading flag
      setLoading(true);

      // Fetch users
      fetchjson('users', { letter: params.get('letter') })
        .then(setUsers)
        .catch(({ message }) => dispatch(showNotification(message)))
        .finally(() => setLoading(false));

    // ...empty users
    } else {
      setUsers(null);
    }
  }, [location.search, fetchjson, dispatch]);

  // Handle click letter
  const handleClickLetter = useCallback(value => {
    // Set query params
    const params = new URLSearchParams(location.search);

    if (value) {
      params.set('letter', value);
    } else {
      params.delete('letter', value);
    }

    // Reload url
    navigate(`?${params}`, { replace: true });
  }, [location.search]);

  // Handle change display
  const handleChangeDisplay = useCallback(value => {
    dispatch(setDisplay(value));
  }, [dispatch]);

  // Retrieve params
  const params = new URLSearchParams(location.search);
  const letters = !users || !params.get('q') ? AZ : AZ.filter(item => includesLetter(users, item));

  return (
    <>
      <div className="py-6 md:pt-12 md:pb-32" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold text-center md:text-2xl">
            Annuaire des membres
            <small className="block text-base font-normal">
              Rechercher, consulter  ...
            </small>
          </h2>
        </div>
      </div>
      <div className="lg:container lg:mx-auto lg:px-4">
        <div className="flex flex-col justify-between bg-white px-6 py-4 shadow-lg md:py-4 md:py-8 md:h-48 lg:p-8 md:-mt-20 xl:-mt-24 xl:rounded">
          <div className="flex-grow">
            <SearchInput query={params.get('q') || ''} />
          </div>
          <Alphabet
            letter={params.get('letter')}
            letters={letters}
            onClick={handleClickLetter}
          />
        </div>
      </div>
      <div className="fade-in min-h-screen">
        <div className="mx-4 my-6 md:container md:mx-auto md:my-12 md:px-4">
          {params.get('q') || params.get('letter')
            ? (
              <div className="flex items-center justify-between mb-4 md:mb-8">
                {params.get('letter')
                  ? <h2 className="text-5xl font-light">{params.get('letter')}</h2>
                  : <h2 className="text-lg">Résultats</h2>}
                <div className="hidden md:flex md:items-center">
                  Affichage :
                  <button
                    type="button"
                    onClick={() => handleChangeDisplay('card')}
                    className={classNames('mx-2', { 'text-gray-300': display !== 'card' })}
                  >
                    <Icon name="grid" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangeDisplay('table')}
                    className={classNames('mx-2', { 'text-gray-300': display === 'card' })}
                  >
                    <Icon name="list" />
                  </button>
                </div>
                <select onChange={event => handleClickLetter(event.target.value)} value={params.get('letter')} className="p-1 bg-white rounded border border-gray-200 md:hidden">
                  <option value="">A-Z</option>
                  {letters.map(item => (
                    <option key={`alphabetical-${item}`} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            )
            : (
              <div className="flex items-center justify-between italic font-semibold mb-6 md:text-xl">
                Ils font partie de nos membres
                <select onChange={event => handleClickLetter(event.target.value)} className="p-1 bg-white rounded border border-gray-200 md:hidden">
                  <option value="">A-Z</option>
                  {AZ.map(item => (
                    <option key={`alphabetical-${item}`} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            )}
          <Users
            data={users ? filterUsers(users, params.get('letter')) : randomly}
            display={users ? display : 'card'}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
