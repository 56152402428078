/** Update role state when action "credentials:store" is dispatched */
export default (state = null, { type, payload }) => {
  switch (type) {
    // Get "role" claim
    case 'credentials:store':
      try {
        const { role } = JSON.parse(atob(payload.token.split('.')[1]));
        return role;
      } catch (error) {
        return null;
      }

    // Reset
    case 'credentials:remove':
      return null;

    default:
      return state;
  }
};
