import React, { useState, useCallback, useEffect } from 'react';
import { Editor, CompositeDecorator, ContentState, EditorState, RichUtils, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import EditorButton from './editor-button';
import LinkDialog from './link-dialog';
import 'draft-js/dist/Draft.css';

type Props = {
  name: string,
  defaultValue?: string,
};

// Find entities for link decorator
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    },
    callback,
  );
}

// Link
/* eslint-disable react/destructuring-assignment, react/prop-types */
const Link = props => <a href={props.contentState.getEntity(props.entityKey).getData()} className="text-blue-600 underline">{props.children}</a>;
/* eslint-enable react/destructuring-assignment, react/prop-types */

// Link decorator
const LinkDecorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link }]);

export default ({ name, defaultValue = '' }: Props) => {
  // Local state
  const [state, setState] = useState(() => EditorState.createEmpty(LinkDecorator));
  const [hidden, setHidden] = useState(true);
  const [url_value, setURLValue] = useState('');

  // Update content
  useEffect(() => setState(EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(defaultValue)),
    LinkDecorator,
  )), [defaultValue]);

  // Toggle inline style
  const handleToggleInlineStyle = useCallback(style => (
    setState(RichUtils.toggleInlineStyle(state, style))
  ), [state]);

  // Toggle block type
  const handleToggleBlockType = useCallback(type => (
    setState(RichUtils.toggleBlockType(state, type))
  ), [state]);

  // Handle key command
  const handleKeyCommand = useCallback(command => {
    const new_state = RichUtils.handleKeyCommand(state, command);

    if (new_state) {
      setState(new_state);
      return true;
    }

    return false;
  }, [state]);

  // Handle link command
  const handleLinkCommand = useCallback(() => {
    const selection = state.getSelection();

    if (!selection.isCollapsed()) {
      // Find link in selection
      const content = state.getCurrentContent();
      const start_key = state.getSelection().getStartKey();
      const start_offset = state.getSelection().getStartOffset();
      const block_with_link = content.getBlockForKey(start_key);
      const link_key = block_with_link.getEntityAt(start_offset);

      // Init url value
      setURLValue(link_key ? content.getEntity(link_key).getData().url : '');

      // Show dialog
      setHidden(false);
    }
  }, [state]);

  // Handle submit link
  const handleSubmitLink = useCallback(url => {
    // Hide dialog
    setHidden(true);

    // Delete link ?
    if (!url) {
      const selection = state.getSelection();

      if (!selection.isCollapsed()) {
        setState(RichUtils.toggleLink(state, selection, null));
      }

      return;
    }

    // Get editor content
    const content = state.getCurrentContent();
    const content_with_entity = content.createEntity('LINK', 'MUTABLE', { url });
    const entity_key = content_with_entity.getLastCreatedEntityKey();

    // Apply entity
    const new_state = EditorState.set(state, { currentContent: content_with_entity });

    // Apply selection
    setState(RichUtils.toggleLink(new_state, new_state.getSelection(), entity_key));
  }, [state]);

  return (
    <>
      <div className="bg-white border border-gray-200 rounded w-full px-4 pt-3 pb-6 text-gray-700 rich-text">
        <input type="hidden" name={name} value={draftToHtml(convertToRaw(state.getCurrentContent()))} />
        <div className="flex items-center border-b border-teal-100 mb-4 pb-1">
          <EditorButton
            name="header-one"
            text="Titre 1"
            active={state.getCurrentContent().getBlockForKey(state.getSelection().getStartKey()).getType() === 'header-one'}
            onClick={handleToggleBlockType}
          />
          <EditorButton
            name="header-two"
            text="Titre 2"
            active={state.getCurrentContent().getBlockForKey(state.getSelection().getStartKey()).getType() === 'header-two'}
            onClick={handleToggleBlockType}
          />
          <EditorButton
            name="header-three"
            text="Titre 3"
            active={state.getCurrentContent().getBlockForKey(state.getSelection().getStartKey()).getType() === 'header-three'}
            onClick={handleToggleBlockType}
          />
          <EditorButton
            name="unordered-list-item"
            icon="list"
            active={state.getCurrentContent().getBlockForKey(state.getSelection().getStartKey()).getType() === 'unordered-list-item'}
            onClick={handleToggleBlockType}
          />
          <EditorButton
            name="BOLD"
            icon="bold"
            active={state.getCurrentInlineStyle().has('BOLD')}
            onClick={handleToggleInlineStyle}
          />
          <EditorButton
            name="ITALIC"
            icon="italic"
            active={state.getCurrentInlineStyle().has('ITALIC')}
            onClick={handleToggleInlineStyle}
          />
          <EditorButton
            name="UNDERLINE"
            icon="underline"
            active={state.getCurrentInlineStyle().has('UNDERLINE')}
            onClick={handleToggleInlineStyle}
          />
          <EditorButton
            name="LINK"
            icon="link"
            active={false}
            onClick={handleLinkCommand}
          />
        </div>
        <Editor
          editorState={state}
          handleKeyCommand={handleKeyCommand}
          onChange={setState}
          onTab={event => {
            setState(RichUtils.onTab(event, state, 4));
          }}
          spellCheck
        />
      </div>
      <LinkDialog initialValue={url_value} hidden={hidden} onSubmit={handleSubmitLink} onDismiss={() => setHidden(true)} />
    </>
  );
};
