import React from 'react';

type Props = {
  display: string,
};

/* eslint-disable react/no-array-index-key */
export default ({ display }: Props) => {
  // Table ?
  if (display === 'table') {
    return (
      <div className="fade-in animate-pulse">
        <div className="flex items-center text-gray-400 pb-2 border-b border-teal-200 mb-2">
          <div className="w-5/12 flex items-center">
            <span className="w-1/2 px-2">Nom & Prénom</span>
            <span className="w-1/2 px-2">Fonction</span>
          </div>
          <span className="w-3/12 px-2">Organization</span>
          <span className="w-2/12 px-2">Email</span>
          <span className="w-2/12 px-2">Téléphone</span>
        </div>
        {new Array(6).fill(null).map((nop, item) => (
          <div key={`placeholder-table-${item}`} className="flex items-center py-2 py-4 border-b border-teal-100 text-sm">
            <div className="flex items-center w-5/12">
              <div className="flex items-center w-1/2 px-2">
                <span className="flex h-4 w-1/3 rounded bg-teal-100 mr-2" />
                <span className="flex h-4 w-1/4 rounded bg-teal-100" />
              </div>
              <div className="w-1/2 truncate px-2">
                <span className="flex h-4 w-7/12 rounded bg-teal-100" />
              </div>
            </div>
            <div className="w-3/12 truncate px-2">
              <span className="flex h-4 w-4/12 rounded bg-teal-100" />
            </div>
            <div className="w-2/12 truncate px-2">
              <span className="flex h-4 w-5/12 rounded bg-teal-100" />
            </div>
            <div className="w-2/12 truncate px-2">
              <span className="flex h-4 w-3/12 rounded bg-teal-100" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="fade-in grid gap-4 grid-cols-1 md:grid-cols-3">
      {new Array(6).fill((nop, i) => i).map(item => (
        <div key={`placeholder-table-${item}`} className="bg-white rounded px-6 py-4 hover:shadow-lg flex flex-col">
          <div className="animate-pulse">
            <div className="flex h-6 w-2/3 rounded bg-teal-100 mb-2" />
            <div className="h-3 w-4/12 rounded bg-teal-100 mb-1" />
            <div className="h-3 w-7/12 rounded bg-teal-100 mb-4" />
            <div className="flex items-center mt-10 mb-1">
              <div className="h-4 w-4 bg-teal-100 rounded-full mr-2" />
              <div className="h-3 w-6/12 rounded bg-teal-100" />
            </div>
            <div className="flex items-center mb-1">
              <div className="h-4 w-4 bg-teal-100 rounded-full mr-2" />
              <div className="h-3 w-3/12 rounded bg-teal-100" />
            </div>
            <div className="flex items-center mb-1">
              <div className="h-4 w-4 bg-teal-100 rounded-full mr-2" />
              <div className="h-3 w-5/12 rounded bg-teal-100" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
