import React from 'react';

type Props = {
  values: {},
  onChange: () => null,
};

export default ({ values, onChange }: Props) => (
  <div className="dropdown-content">
    <ul role="menu" className="dropdown-list">
      {Object.keys(values).map(key => (
        <li key={`dropdown-${key}`} className="dropdown-item">
          <button
            type="button"
            role="menuitem"
            className="dropdown-btn"
            onClick={() => onChange(key)}
          >
            {values[key]}
          </button>
        </li>
      ))}
    </ul>
  </div>
);
