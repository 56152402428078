import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { hideNotification, destroyNotification } from 'actions/notification';
import Icon from './icon';

const icons = {
  success: 'check-circle',
  warning: 'alert-octagon',
  error: 'alert-triangle',
};

export default function Notification() {
  // Redux
  const dispatch = useDispatch();

  // Get state
  const {
    status,
    template,
    message,
    delay,
  } = useSelector(state => state.notification, shallowEqual);

  // Timer to auto hide notification
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (status === 'show' && delay > 0) {
      const timer = setTimeout(() => dispatch(hideNotification()), delay * 1000);
      return () => clearTimeout(timer);
    }

    if (status === 'hide') {
      const timer = setTimeout(() => dispatch(destroyNotification()), 700);
      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, status, dispatch]);

  // Destroyed status ?
  if (!status || status === 'destroy') {
    return null;
  }

  // Render
  return (
    <div
      role="presentation"
      onClick={() => dispatch(hideNotification())}
      className={classNames('cursor-default flex items-center justify-center text-white fixed z-50 px-8 py-6 whitespace-nowrap',
        `notification-${status}`,
        {
          'bg-blue-500': template !== 'success',
          'bg-green-900': template === 'success',
        })}
      style={{ left: '50%', top: '135px', transform: 'translate3d(-50%, 0, 0)' }}
    >
      <Icon name={icons[template] || 'info'} className="mr-2" />
      <div>
        {message.split('\n').map(item => <p key={item}>{item}</p>)}
      </div>
    </div>
  );
}
