import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'hooks/fetch';
import { showNotification } from 'actions/notification';
import ErrorPage from 'pages/error';
import Button from 'components/button';
import Icon from 'components/icon';
import Form from 'components/form';
import TextInput from 'components/text-input';

export default function Password() {
  // Redux
  const dispatch = useDispatch();
  const fetchjson = useFetch();
  const role = useSelector(state => state.role);

  // Handle submit
  const handleSubmit = useCallback(data => {
    fetchjson('PUT users/password', data)
      .then(() => dispatch(showNotification('Le mot de passe a été mis à jour avec succès !', 'success')))
      .catch(({ message }) => dispatch(showNotification(message)));
  }, [dispatch, fetchjson]);

  // Not admin ?
  if (role !== 'admin') {
    return <ErrorPage message="Accès non autorisé" />;
  }

  return (
    <>
      <div className="px-4 py-6 md:py-10 2xl:px-0" style={{ background: 'radial-gradient(#cf1f39, #a5182d)' }}>
        <div className="container mx-auto">
          <h2 className="text-lg text-white font-semibold flex items-center md:text-xl">
            <button type="button" onClick={() => navigate(-1)} className="leading-none mr-2">
              <Icon name="arrow-left" className="text-2xl text-white md:text-3xl" />
            </button>
            Modifier le mot de passe
          </h2>
        </div>
      </div>
      <div className="container my-8 px-4 md:my-12 md:mx-auto 2xl:px-0 md:w-1/4">
        <Form onSubmit={handleSubmit}>
          <div className="text-sm text-gray-700 mb-1">Mot de passe actuel</div>
          <TextInput name="current" type="password" required />
          <div className="text-sm text-gray-700 mt-4 mb-1">Nouveau mot de passe</div>
          <TextInput name="password" type="password" required />
          <div className="my-12 flex justify-center">
            <Button icon="check-circle" type="submit">Valider</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
